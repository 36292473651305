.form-page {
  margin: 0 auto;
  min-width: 950px;
  padding: 0 30px 65px 30px;
}
.form-page .tabs {
  width: 100%;
  max-width: 950px;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
  display: flex;
  flex-direction: row;
}
.form-page .tabs .a-tab {
  color: var(--cool-grey);
  width: 140px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.form-page .tabs .a-tab.active {
  color: var(--dark-sky-blue);
  border: solid var(--dark-sky-blue);
  border-width: 0 0 1px 0;
}
.form-page .bot-setting-content .setting-part {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}
.form-page .bot-setting-content .setting-part .liff-url {
  margin-bottom: 20px;
}
.form-page .bot-setting-content .setting-part .liff-url .fa-copy {
  cursor: pointer;
}
.form-page .bot-setting-content .setting-part .liff-url .fa-copy:hover {
  color: var(--dark-sky-blue);
}
.form-page .bot-setting-content .setting-part .liff-url .fa-check {
  color: var(--dark-sky-blue);
}
.form-page .bot-setting-content .setting-part .third-title {
  font-size: 16px;
  color: var(--dark-grey);
  margin-bottom: 10px;
}
.form-page .bot-setting-content .setting-part textarea {
  margin-bottom: 30px;
  font-size: 14px;
  color: var(--slate-grey);
  min-height: 70px;
}
.form-page .bot-setting-content .setting-part .custom-forms .add-more-button {
  width: 150px;
  margin-top: 10px;
  margin-bottom: 40px;
}
