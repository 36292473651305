.upload-image {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-image .default-img {
  width: 80px;
  height: 80px;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-image .default-img .fa-image {
  color: #e9e9e9;
  font-size: 30px;
}
.upload-image .default-img .fa-exclamation-triangle {
  color: #f6545f;
  font-size: 30px;
}
.upload-image figure {
  width: 80px;
  height: 80px;
}
.upload-image .image-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 80px;
}
.upload-image .image-info .restriction {
  font-size: 12px;
}
.upload-image .image-info .image-name {
  font-size: 12px;
  color: #141516;
}
.upload-image .image-info .error-msg {
  color: #f6545f;
  font-size: 10px;
}
.upload-image .image-info button {
  min-width: 80px;
  padding: 0 10px;
  width: 80px;
  height: 30px;
  min-height: 30px;
  line-height: 30px;
}
.upload-image.error .default-img {
  background-color: #feedef;
  border-color: #f6545f;
}
