.show-updating {
  display: flex;
  align-items: center;
}
.show-updating .fa-spinner-third,
.show-updating .fa-check-circle {
  color: var(--greenish-teal);
  margin-right: 5px;
}
.show-updating .fa-times {
  color: var(--watermelon);
  margin-right: 5px;
}
.show-updating span {
  font-size: 13px;
  color: var(--late-grey);
}
