.channel-dashboard {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 30px 100px 30px;
}
.channel-dashboard .sub-title {
  margin-bottom: 20px;
  color: #141516;
  font-size: 18px;
}
.channel-dashboard .overview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 185px;
  margin-bottom: 10px;
}
.channel-dashboard .overview .a-overview-block {
  padding: 15px 20px;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  width: calc((100% - 30px) / 4);
  height: 100%;
}
.channel-dashboard .overview .a-overview-block .block-title {
  font-size: 18px;
  color: var(--dark-grey);
  margin-bottom: 5px;
  height: 50px;
  max-height: 50px;
}
.channel-dashboard .overview .a-overview-block .block-content {
  display: flex;
  flex-direction: column;
}
.channel-dashboard .overview .a-overview-block .block-content .data-number-unit {
  color: var(--slate-grey);
  font-size: 16px;
  margin-bottom: 20px;
  align-self: flex-end;
}
.channel-dashboard .overview .a-overview-block .block-content .data-number-unit span {
  color: var(--dark-grey);
  font-size: 20px;
  font-weight: 600;
}
.channel-dashboard .record-block {
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid var(--white-two);
}
.channel-dashboard .record-block .axis-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.channel-dashboard .record-block .axis-title span {
  color: var(--dark-grey);
  font-size: 12px;
}
.channel-dashboard .record-block .recharts-wrapper {
  margin-bottom: 30px;
}
.channel-dashboard .record-block .recharts-wrapper .recharts-surface .recharts-cartesian-grid-horizontal line {
  stroke: #e9e9e9;
}
.channel-dashboard .record-block .recharts-wrapper .recharts-surface .recharts-cartesian-axis-line {
  stroke: #aeb1b8;
}
.channel-dashboard .record-block .recharts-wrapper .recharts-surface .recharts-xAxis .recharts-cartesian-axis-ticks {
  transform: translateY(10px);
}
.channel-dashboard .record-block .recharts-wrapper .recharts-surface .recharts-text {
  font-size: 13px;
  fill: var(--slate-grey);
}
.channel-dashboard .record-block .recharts-wrapper .custom-tooltip {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  font-size: 14px;
}
.channel-dashboard .record-block .recharts-wrapper .custom-tooltip .tooltip-title {
  margin-bottom: 5px;
}
.channel-dashboard .record-block .recharts-wrapper .custom-tooltip .a-row {
  margin: 5px 0;
}
.channel-dashboard .record-block .recharts-wrapper .custom-tooltip .a-row span {
  margin-left: 5px;
}
.channel-dashboard .record-block .axis-illustration {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.channel-dashboard .record-block .axis-illustration .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.channel-dashboard .record-block .axis-illustration .friends,
.channel-dashboard .record-block .axis-illustration .messages {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.channel-dashboard .record-block .axis-illustration .friends {
  margin-right: 30px;
}
.channel-dashboard .record-block .axis-illustration .friends .dot {
  background-color: #359ced;
}
.channel-dashboard .record-block .axis-illustration .messages .dot {
  background-color: #f7d767;
}
.channel-dashboard .active-statics {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.channel-dashboard .area-chart .recharts-wrapper .recharts-tooltip-wrapper {
  top: 75% !important;
}
.channel-dashboard .area-chart .area-tooltip {
  padding: 5px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px var(--white-three);
  background-color: #ffffff;
  border-radius: 3px;
}
.channel-dashboard .area-chart .area-tooltip .a-row {
  font-size: 12px;
  color: var(--slate-grey);
}
