.chat-dialogue {
  display: flex;
  margin: 30px 30px 0 30px;
  font-size: 13px;
}
.chat-dialogue .Linkify {
  white-space: pre-line;
}
.chat-dialogue .Linkify mark {
  background-color: transparent;
  color: #359ced;
}
.chat-dialogue .dialogue-content {
  flex: 0 0 600px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 70px);
}
.chat-dialogue.hide-avatar {
  margin-left: 100px;
  margin-top: 10px;
}
.chat-dialogue.loading {
  margin-left: 80px;
}
.chat-dialogue.join {
  margin-left: 100px;
  background-color: #aeb1b8;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  border-radius: 3px;
  padding: 5px 10px;
}
.chat-dialogue.rich-image .dialogue-content a {
  display: contents;
}
.chat-dialogue.rich-image .dialogue-content a figure {
  width: 300px;
  height: 300px;
}
.chat-dialogue.files .dialogue-content .multi-files {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chat-dialogue.files .dialogue-content .multi-files .a-file {
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  padding: 15px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: unset;
}
.chat-dialogue.files .dialogue-content .multi-files .a-file .file-icon {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-dialogue.files .dialogue-content .multi-files .a-file .file-icon .fal {
  font-size: 30px;
  color: #aeb1b8;
}
.chat-dialogue.files .dialogue-content .multi-files .a-file .file-info {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.chat-dialogue.files .dialogue-content .multi-files .a-file .file-info .file-name {
  font-size: 12px;
  color: #606972;
}
.chat-dialogue.files .dialogue-content .multi-files .a-file .file-info .file-size {
  font-size: 10px;
  color: #aeb1b8;
}
.chat-dialogue.files .dialogue-content .file-thumbnail {
  background-color: #ffffff;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  padding: 10px;
  max-width: 350px;
}
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block .file-info {
  border: solid var(--white-two);
  border-width: 0 0 0 1px;
  padding-left: 10px;
  margin-right: 20px;
  width: calc(100% - 90px);
}
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block .file-info .file-name {
  color: var(--dark-grey);
  font-size: 13px;
  margin-bottom: 5px;
}
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block .file-info .file-ext,
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block .file-info .file-size,
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block .file-info .expire-date {
  font-size: 12px;
  color: var(--slate-grey);
  margin-bottom: 5px;
}
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block figure {
  width: 70px;
  height: 70px;
}
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block figure img {
  border: 1px solid var(--white-two);
  border-radius: 3px;
}
.chat-dialogue.files .dialogue-content .file-thumbnail .preview-block figure img.is-image {
  border: 0;
}
.chat-dialogue.files .dialogue-content .display-image a {
  display: contents;
}
.chat-dialogue.files .dialogue-content .display-image a figure {
  width: 300px;
  height: 300px;
}
.chat-dialogue.template .dialogue-content .buttons {
  width: 180px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  background-color: #ffffff;
}
.chat-dialogue.template .dialogue-content .buttons .message {
  padding: 10px;
  font-size: 13px;
  color: var(--slate-grey);
}
.chat-dialogue.template .dialogue-content .buttons .actions {
  width: 100%;
}
.chat-dialogue.template .dialogue-content .buttons .actions .a-action {
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: var(--dark-sky-blue);
  border: solid var(--white-two);
  border-width: 1px 0 0 0;
}
.chat-dialogue.activity-card .dialogue-content .content-section {
  width: 200px;
  border-radius: 3px;
  border: solid 1px var(--white-two);
  background-color: #ffffff;
}
.chat-dialogue.activity-card .dialogue-content .content-section .title-and-text {
  margin: 10px;
}
.chat-dialogue.activity-card .dialogue-content .content-section .title-and-text .title {
  font-size: 15px;
  font-weight: 600;
  color: var(--dark-grey);
  line-height: 1.43;
}
.chat-dialogue.activity-card .dialogue-content .content-section .title-and-text .text {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.67;
}
.chat-dialogue.activity-card .dialogue-content .content-section .actions {
  width: 100%;
}
.chat-dialogue.activity-card .dialogue-content .content-section .actions .a-action {
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: var(--dark-sky-blue);
  border: solid var(--white-two);
  border-width: 1px 0 0 0;
}
@media only screen and (max-width: 768px) {
  .chat-dialogue {
    margin: 10px 10px 0 10px;
  }
  .chat-dialogue .dialogue-content {
    flex: 1 0 auto;
    width: calc(100% - 50px);
  }
  .chat-dialogue.hide-avatar {
    margin-left: 60px;
  }
}
.histories .name-n-time {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.histories .name-n-time .speaker {
  font-size: 16px;
  color: #141516;
  margin-right: 10px;
  white-space: nowrap;
}
.histories .name-n-time .message-time {
  font-size: 11px;
  color: #aeb1b8;
}
.histories .user-avatar {
  flex: 0 0 70px;
}
.histories .user-avatar figure {
  width: 50px;
  height: 50px;
}
.histories .user-avatar figure img {
  border-radius: 50%;
}
.histories .date-horizon-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.histories .date-horizon-line .line {
  flex: 1;
  border: 0.5px solid #e9e9e9;
  height: 0;
}
.histories .date-horizon-line .date {
  color: #e9e9e9;
  text-align: center;
  flex: 0 0 140px;
  font-size: 12px;
}
@media only screen and (max-width: 768px) {
  .histories .user-avatar {
    flex: 0 0 50px;
  }
  .histories .user-avatar figure {
    width: 40px;
    height: 40px;
  }
  .histories .date-horizon-line {
    margin-top: 10px;
  }
  .histories .name-n-time {
    flex-direction: column;
    align-items: baseline;
  }
}
