.a-overview-section {
  width: 100%;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  margin-bottom: 10px;
}
.a-overview-section .head-part {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 55px;
  padding: 0 20px;
  justify-content: space-between;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
}
.a-overview-section .head-part .title-part {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.a-overview-section .head-part .title-part .overview-title {
  color: var(--dark-grey);
  font-size: 18px;
}
.a-overview-section .head-part .title-part .tooltip {
  margin-left: 10px;
}
.a-overview-section .head-part .title-part .tooltip .fa-info-circle {
  color: var(--cool-grey);
  cursor: pointer;
}
.a-overview-section .head-part .select-part {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.a-overview-section .head-part .select-part span {
  margin-right: 10px;
}
.a-overview-section .head-part .select-part .usage-select {
  padding: 0 10px;
}
.a-overview-section .head-part .select-part .usage-select:before {
  top: 0;
}
.a-overview-section .content-part {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 96px;
}
.a-overview-section .content-part .a-overview-block {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc((100% - 20px) / 3);
  height: 100%;
}
.a-overview-section .content-part .a-overview-block .block-title {
  font-size: 16px;
  color: var(--slate-grey);
  height: 35px;
  max-height: 35px;
  margin-bottom: 5px;
}
.a-overview-section .content-part .a-overview-block .block-title .alert {
  font-size: 12px;
  color: var(--watermelon);
}
.a-overview-section .content-part .a-overview-block .block-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.a-overview-section .content-part .a-overview-block .block-content .data-number-unit {
  color: var(--slate-grey);
  font-size: 16px;
}
.a-overview-section .content-part .a-overview-block .block-content .data-number-unit span {
  color: var(--dark-grey);
  font-size: 20px;
  font-weight: 600;
}
.a-overview-section .content-part .a-overview-block .block-content.hide-chart {
  flex-direction: column;
}
.a-overview-section .content-part .a-overview-block .block-content.hide-chart .data-number-unit {
  align-self: flex-end;
}
.a-overview-section.unrecognized-q .content-part {
  max-height: 170px;
  height: 170px;
  padding: 15px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.a-overview-section.unrecognized-q .content-part .a-question {
  color: var(--dark-grey);
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 10px;
}
.a-overview-section.unrecognized-q .content-part .a-question .no {
  margin-right: 5px;
}
