.channel-message-center {
  width: 100%;
  min-width: 964px;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 768px) {
  .channel-message-center {
    min-width: 260px;
  }
}
