.message-control-modal section .modal-title {
  display: flex;
  flex-direction: column;
}
.message-control-modal section .modal-title .a-line {
  text-align: center;
}
.message-control-modal section .button-section {
  justify-content: center;
  display: flex;
}
