.console-channels {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 20px;
  height: calc(100vh - 80px);
}
.console-channels .page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}
.console-channels .c-btn {
  width: 150px;
}
.console-channels .channel-list {
  margin-bottom: 20px;
}
.console-channels .a-channel {
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  margin: 0 30px;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
}
.console-channels .a-channel .channel-avatar {
  width: 50px;
  height: 50px;
  background-color: #f7d767;
  border-radius: 50%;
}
.console-channels .a-channel .channel-avatar figure {
  width: 50px;
  height: 50px;
}
.console-channels .a-channel .channel-avatar figure img {
  border-radius: 50%;
}
.console-channels .a-channel .channel-brief {
  width: calc(100% - 50px - 120px);
  padding-left: 20px;
}
.console-channels .a-channel .channel-brief .channel-name {
  margin-top: 15px;
  font-size: 18px;
}
.console-channels .a-channel .channel-brief .icon-with-text {
  margin-top: 20px;
  font-size: 14px;
}
.console-channels .a-channel .channel-brief .icon-with-text.danger span.number {
  color: var(--watermelon);
}
.console-channels .a-channel .channel-control a {
  background-color: #ffffff;
  color: #359ced;
  border-color: #359ced;
}
.console-channels .a-channel .channel-control a:hover {
  background-color: #359ced;
  color: #ffffff;
}
.console-channels .a-channel:last-child {
  border-width: 0;
}
