.cowell-detail {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  padding: 0 20px 65px 20px;
}
.cowell-detail .sub-title {
  color: #141516;
  font-size: 18px;
  margin-bottom: 15px;
}
.cowell-detail .sub-title.no-margin {
  margin-bottom: 0;
}
.cowell-detail .travel-info {
  margin-bottom: 15px;
}
.cowell-detail .travel-info .a-info {
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 20px;
}
.cowell-detail .match-block {
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  width: 430px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
}
.cowell-detail .match-block .top-section {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
}
.cowell-detail .match-block .top-section .matched-platform {
  color: #00b900;
  margin-bottom: 12px;
}
.cowell-detail .match-block .top-section .friend-info {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.cowell-detail .match-block .top-section .friend-info figure {
  width: 50px;
  height: 50px;
  position: relative;
}
.cowell-detail .match-block .top-section .friend-info figure img {
  border-radius: 50%;
}
.cowell-detail .match-block .top-section .friend-info .friend-info {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  margin-left: 10px;
  justify-content: space-around;
}
.cowell-detail .match-block .top-section .friend-info .friend-info .name-n-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cowell-detail .match-block .top-section .friend-info .friend-info .name-n-time .friend-name {
  font-size: 15px;
  color: #141516;
}
.cowell-detail .match-block .top-section .friend-info .friend-info .name-n-time .time-from-now {
  font-size: 10px;
  color: #aeb1b8;
}
.cowell-detail .match-block .top-section .friend-info .friend-info .lastest-msg {
  font-size: 13px;
}
.cowell-detail .match-block .top-section .no-match {
  color: #aeb1b8;
  font-size: 16px;
  margin-bottom: 12px;
}
.cowell-detail .match-block .top-section .no-match-des {
  font-size: 13px;
}
.cowell-detail .match-block .bottom-section {
  border: solid #e9e9e9;
  border-width: 1px 0 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cowell-detail .match-block .bottom-section .fal {
  color: #aeb1b8;
  cursor: pointer;
}
.cowell-detail .match-block .bottom-section .fal:hover {
  color: #359ced;
}
.cowell-detail .match-block .bottom-section .fal:active {
  color: #2b80c4;
}
.cowell-detail .a-travel-record {
  padding: 15px 0;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
}
.cowell-detail .a-travel-record .record-name {
  color: #141516;
  font-size: 15px;
  margin-bottom: 10px;
}
.cowell-detail .a-travel-record .tag-list {
  font-size: 13px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cowell-detail .a-travel-record .tag-list .a-tag {
  margin-right: 20px;
}
.cowell-detail .a-travel-record .tag-list .a-tag span {
  margin-left: 5px;
  white-space: nowrap;
}
.cowell-detail .a-travel-record .tag-list .a-tag:last-child {
  margin-right: 0;
}
.cowell-detail .a-travel-record:last-child {
  margin-bottom: 10px;
  border: 0;
}
