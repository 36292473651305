.console-channel {
  width: 100%;
}
.console-channel .mobile-sub-header {
  display: none;
}
.console-channel .sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  border: solid var(--white-two);
  border-width: 1px 0 0 0;
  z-index: 2;
  position: relative;
  justify-content: space-between;
}
.console-channel .sub-header .show-updating {
  margin-right: 50px;
}
.console-channel .sub-header .channel-tabs {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.console-channel .sub-header .channel-tabs .a-tab {
  width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: unset;
  color: var(--slate-grey);
  cursor: pointer;
}
.console-channel .sub-header .channel-tabs .a-tab .fa-chevron-down {
  margin-left: 10px;
  font-size: 12px;
}
.console-channel .sub-header .channel-tabs .a-tab.active {
  color: var(--dark-sky-blue);
}
.console-channel .sub-header .channel-tabs .a-tab.active .fa-chevron-down {
  color: var(--dark-sky-blue);
}
.console-channel .sub-header .channel-tabs .a-tab:focus {
  outline: 0;
}
.console-channel .tab-content {
  background-color: #fafafb;
  height: calc(100vh - 160px);
  overflow-y: auto;
}
.console-channel .tab-content .page-title {
  font-size: 25px;
  padding: 30px 0;
  font-weight: 600;
}
.console-channel .tab-content .page-title .fa-chevron-right {
  margin: 0 10px;
}
.console-channel .tab-content .page-title a {
  text-decoration: unset;
  color: var(--slate-grey);
}
.console-channel .tab-content .page-title-with-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}
.console-channel .tab-content .page-title-with-button p {
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}
.console-channel .tab-content .page-title-with-button p .fa-chevron-right {
  margin: 0 10px;
  font-size: 20px;
}
.console-channel .tab-content .page-des {
  font-size: 13px;
  margin-bottom: 30px;
}
.console-channel .tab-content .content-description {
  padding: 20px 0;
  font-size: 13px;
}
.console-channel .tab-content .a-block {
  padding: 30px 0;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
  width: 100%;
}
.console-channel .tab-content .a-block:last-child {
  border: 0;
}
@media only screen and (min-width: 769px) and (max-width: 1366px) {
  .console-channel .sub-header {
    height: 60px;
  }
  .console-channel .tab-content {
    height: calc(100vh - 120px);
  }
}
@media only screen and (max-width: 768px) {
  .console-channel .sub-header {
    display: none;
  }
  .console-channel .mobile-sub-header {
    display: flex;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 61px;
  }
  .console-channel .mobile-sub-header .float-bars {
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 var(--white-two);
    padding: 5px 7px;
    margin: 16px 20px;
    font-size: 16px;
  }
  .console-channel .mobile-sub-header .channel-menu {
    width: 100vw;
    height: calc(100vh - 60px);
    background-color: #ffffff;
  }
  .console-channel .mobile-sub-header .channel-menu .icon-part {
    position: absolute;
    right: 0;
    margin: 20px;
    font-size: 16px;
  }
  .console-channel .tab-content {
    height: calc(100vh - 60px);
  }
}
#tab-menu-dropdown .a-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--slate-grey);
  text-decoration: unset;
}
#tab-menu-dropdown .a-tab.active li {
  color: var(--dark-sky-blue);
}
