.auto-assign-page {
  margin: 0 auto;
  padding: 0 30px 65px 30px;
}
.auto-assign-page .bot-setting-content .button-group {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.auto-assign-page .bot-setting-content .setting-part .keyword-section .input-section {
  display: flex;
  padding: 20px;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  margin-bottom: 10px;
  background-color: var(--white-three);
}
.auto-assign-page .bot-setting-content .setting-part .keyword-section .input-section input {
  flex: 1;
}
.auto-assign-page .bot-setting-content .setting-part .keyword-section .input-section .keyword-text {
  flex-grow: 0;
  flex-basis: 81px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  margin: 0 20px;
  text-align: center;
  color: --dark-grey;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings {
  margin-bottom: 20px;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings .a-setting {
  padding: 20px;
  border: 1px solid var(--white-two);
  background-color: var(--white-three);
  border-radius: 3px;
  margin-bottom: 10px;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings .a-setting .top-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings .a-setting .top-section .left-section {
  display: flex;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings .a-setting .top-section .left-section .title {
  font-size: 16px;
  line-height: 30px;
  margin-right: 15px;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings .a-setting .top-section .left-section .dropdown-list .op-select {
  line-height: unset;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings .a-setting .top-section .left-section .dropdown-list .op-select::before {
  top: 0px;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings .a-setting .top-section .delete-icon {
  color: #aeb1b8;
  cursor: pointer;
  font-size: 12px;
}
.auto-assign-page .bot-setting-content .setting-part .setting-section .settings .a-setting .top-section .delete-icon:hover {
  color: #f6545f;
}
