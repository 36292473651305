.keyword-management .search-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.keyword-management .search-section .search-input {
  max-width: 210px;
}
.keyword-management .search-section .search-input.valid .fa-search {
  cursor: pointer;
}
.keyword-management .search-section .view-all-link {
  text-decoration: none;
  color: var(--dark-sky-blue);
}
.keyword-management .keyword-section {
  display: flex;
  flex-direction: column;
}
.keyword-management .keyword-section .recent-keyword {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.keyword-management .keyword-section .recent-keyword .recent-keyword-title {
  color: var(--gunmetal);
  margin-bottom: 10px;
  font-size: 14px;
}
.keyword-management .keyword-section .recent-keyword .recent-keyword-list {
  display: flex;
  flex-wrap: wrap;
}
.keyword-management .keyword-section .level-keyword {
  display: flex;
  flex-direction: column;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section {
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--white-two);
  background-color: var(--white-three);
  margin-bottom: 10px;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 10px;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .title-part {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  font-size: 18px;
  color: var(--black);
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .title-part .indent figure {
  width: 16px;
  height: 16px;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .title-part .indent figure img {
  object-fit: contain;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .title-part .indent .fa-ellipsis-v {
  width: 16px;
  color: var(--cool-grey);
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .add-keyword-part {
  display: flex;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .add-keyword-part input {
  min-height: 25px;
  height: 25px;
  font-size: 14px;
  max-width: 150px;
  margin-right: 5px;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .add-keyword-part .add-button {
  background-color: var(--dark-sky-blue);
  color: #ffffff;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 3px;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .add-keyword-part .icon-loading {
  width: 25px;
  margin: 0;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-top .add-keyword-part .icon-loading i {
  font-size: 16px;
}
.keyword-management .keyword-section .level-keyword .a-keyword-section .section-bottom .keyword-list {
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0 10px 25px;
}
