.calendar {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-height: 165px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  z-index: 10;
  min-width: 180px;
  position: relative;
}
.calendar .select-date {
  background-color: #fafafb;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.calendar .select-date .calendar-select {
  width: 75px;
  height: 20px;
  font-size: 12px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  min-height: 20px;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
}
.calendar .select-date .calendar-select:not(select):before {
  background-image: none !important;
  font-family: "Font Awesome 5 Pro";
  content: "\f0d7";
  font-weight: 600;
  width: 20px;
  height: 18px;
  line-height: 18px;
  top: 0;
  right: 0;
  color: #359ced;
  text-align: center;
}
.calendar .select-date .calendar-select.year {
  margin-left: 5px;
}
.calendar .select-date .c-txt__input--select:hover:not(.is-disabled):not([disabled]):not(select):before {
  background-image: none;
}
.calendar .a-month .weekdays {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 5px;
}
.calendar .a-month .weekdays .weekday-name {
  font-weight: 600;
  color: #262626;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 12px;
  width: 25px;
  height: 20px;
}
.calendar .a-month .dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0;
  margin-top: 5px;
}
.calendar .a-month .dates .a-day {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  color: #8c8c8c;
  cursor: pointer;
  font-size: 12px;
  width: 25px;
  height: 20px;
}
.calendar .a-month .dates .a-day.not-this-month {
  color: #d8d8d8;
}
.calendar .a-month .dates .a-day:hover,
.calendar .a-month .dates .a-day.active {
  background-color: var(--dark-sky-blue);
  color: #ffffff;
}
.calendar .a-month .dates .a-day.disabled {
  color: var(--white-two);
  background-color: #ffffff;
  cursor: initial;
}
.calendar-dropdown {
  min-width: 75px !important;
  max-height: 400px;
  overflow: auto;
}
.calendar-dropdown .calendar-select-item {
  cursor: pointer;
  padding: 5px;
}
.calendar-dropdown .calendar-select-item:hover {
  background-color: #e6f4ff;
}
