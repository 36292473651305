/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */
.l-backdrop {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 400;
  background-color: rgba(47, 57, 65, 0.85);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.l-backdrop--center {
  align-items: center;
  justify-content: center;
}
.l-backdrop.is-rtl {
  direction: rtl;
}
.l-backdrop.is-visible {
  -webkit-animation: zd-backdrop-visible 0.15s ease-in;
  animation: zd-backdrop-visible 0.15s ease-in;
}
.l-backdrop .c-dialog {
  -webkit-animation-delay: 0.01s;
  animation-delay: 0.01s;
  margin: 48px;
  border-color: transparent;
  box-shadow: 0 20px 28px 0 rgba(47, 57, 65, 0.35);
  max-height: calc(100vh - 96px);
  overflow: auto;
}
.l-backdrop--center .c-dialog {
  margin: 0;
}
.l-backdrop--center .c-dialog,
_:-ms-input-placeholder {
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
@media (max-height: 399px) {
  .l-backdrop .c-dialog {
    top: 24px;
    bottom: auto;
    margin-bottom: 24px;
    max-height: none;
  }
}
@media (max-width: 699px) {
  .l-backdrop .c-dialog {
    left: 24px;
  }
  .l-backdrop.is-rtl .c-dialog {
    right: 24px;
    left: auto;
  }
}
.c-dialog {
  display: flex;
  position: fixed;
  flex-direction: column;
  margin-bottom: 48px;
  border: 1px solid #d8dcde;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 20px 28px 0 rgba(4, 68, 77, 0.15);
  background-color: #fff;
  width: 544px;
  min-height: 60px;
}
.c-dialog:focus {
  outline: none;
}
.c-dialog--large {
  width: 800px;
}
.c-dialog.is-open {
  -webkit-animation-name: zd-dialog-open;
  animation-name: zd-dialog-open;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
.c-dialog.is-rtl {
  direction: rtl;
}
.c-dialog__header {
  display: block;
  margin: 0;
  border-bottom: 1px solid #e9ebed;
  padding: 20px 40px;
  line-height: 1.42857;
  color: #2f3941;
  font-size: 14px;
  font-weight: 600;
}
.c-dialog__header--danger {
  position: relative;
  color: #cc3340;
}
.c-dialog__header--danger:before {
  position: absolute;
  top: 22px;
  left: 16px;
  background: no-repeat 3.125% url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23CC3340'%3E%3Cg fill='none' stroke='currentColor'%3E%3Ccircle cx='7.5' cy='8.5' r='7'/%3E%3Cpath stroke-linecap='round' d='M7.5 4.5V9'/%3E%3C/g%3E%3Ccircle cx='7.5' cy='12' r='1' fill='currentColor'/%3E%3C/svg%3E");
  width: 16px;
  height: 16px;
  content: "";
}
.c-dialog.is-rtl .c-dialog__header--danger:before {
  right: 16px;
  left: auto;
}
.c-dialog__close {
  display: block;
  position: absolute;
  top: 10px;
  right: 20px;
  transition: background-color 0.1s ease-in-out;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-decoration: none;
  font-size: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.c-dialog__close::-moz-focus-inner {
  border: 0;
}
.c-dialog__close:after,
.c-dialog__close:before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.25s ease-in-out;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 16px;
  content: "";
}
.c-dialog__close:before {
  opacity: 1;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath stroke='currentColor' stroke-linecap='round' d='M3 13L13 3m0 10L3 3'/%3E%3C/svg%3E");
}
.c-dialog__close:after {
  opacity: 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%232F3941'%3E%3Cpath stroke='currentColor' stroke-linecap='round' d='M3 13L13 3m0 10L3 3'/%3E%3C/svg%3E");
}
.c-dialog__close.is-focused:before,
.c-dialog__close.is-hovered:before,
.c-dialog__close:hover:before {
  opacity: 0;
}
.c-dialog__close.is-focused:after,
.c-dialog__close.is-hovered:after,
.c-dialog__close:hover:after {
  opacity: 1;
}
.c-dialog__close.is-focused,
.c-dialog__close:focus {
  outline: none;
  background-color: rgba(104, 115, 125, 0.15);
}
.c-dialog__close.is-active,
.c-dialog__close:active {
  background-color: transparent;
}
.c-dialog.is-rtl .c-dialog__close {
  right: auto;
  left: 20px;
}
.c-dialog__body {
  display: block;
  margin: 0;
  padding: 20px 40px;
  height: 100%;
  overflow: auto;
  line-height: 1.42857;
  color: #2f3941;
  font-size: 14px;
}
.c-dialog__footer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 40px 32px;
}
.c-dialog--large .c-dialog__footer {
  border-top: 1px solid #e9ebed;
  padding: 32px 40px;
}
.c-dialog__footer__item {
  display: flex;
  margin-left: 20px;
  min-width: 0;
}
.c-dialog__footer__item:first-child {
  margin-left: 0;
}
.c-dialog.is-rtl .c-dialog__footer__item {
  margin-right: 20px;
  margin-left: 0;
}
.c-dialog.is-rtl .c-dialog__footer__item:first-child {
  margin-right: 0;
}
@-webkit-keyframes zd-backdrop-visible {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zd-backdrop-visible {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes zd-dialog-open {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  to {
    opacity: 1;
  }
}
@keyframes zd-dialog-open {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  to {
    opacity: 1;
  }
}
.react-modal {
  z-index: 1100;
}
.react-modal .c-dialog {
  padding: 30px;
  display: block;
}
.react-modal .c-dialog .modal-title {
  display: flex;
  justify-content: center;
  font-size: 21px;
  margin-bottom: 20px;
}
.react-modal .c-dialog .modal-description {
  font-size: 13px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.react-modal .c-dialog .c-dialog__close {
  color: #aeb1b8;
}
.react-modal .c-dialog .c-dialog__close:hover {
  color: #606972;
}
.react-modal .c-dialog .c-dialog__close:active {
  color: #141516;
}
.react-modal .c-dialog .big-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.react-modal .c-dialog .big-icon .fal,
.react-modal .c-dialog .big-icon .far {
  font-size: 35px;
}
.react-modal .c-dialog .big-icon .fa-thumbs-up {
  color: #3dcca2;
}
.react-modal .c-dialog .big-icon .fa-spinner-third {
  color: #359ced;
}
.react-modal .c-dialog .button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-modal .c-dialog .button-group button {
  width: 140px;
}
.react-modal.add-member-modal .c-dialog {
  width: 360px;
}
.react-modal.add-member-modal input {
  margin-bottom: 20px;
}
.react-modal.add-member-modal .c-btn {
  width: 100%;
}
.react-modal.add-member-modal .valid-input {
  line-height: 40px;
}
.react-modal.delete-member-modal .c-dialog {
  width: 360px;
}
.react-modal.image-upload-modal .c-dialog {
  width: 360px;
}
.react-modal.image-upload-modal .file-drop-zone {
  width: 300px;
  height: 115px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  background-color: #fafafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
}
.react-modal.image-upload-modal .file-drop-zone .mask {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: transparent;
}
.react-modal.image-upload-modal .file-drop-zone:focus {
  outline: 0;
}
.react-modal.image-upload-modal .file-drop-zone input {
  display: none;
}
.react-modal.image-upload-modal .file-drop-zone .fa-cloud-upload {
  font-size: 30px;
  color: var(--dark-sky-blue);
  margin-bottom: 10px;
}
.react-modal.image-upload-modal .file-drop-zone .fa-exclamation-triangle {
  font-size: 30px;
  color: var(--watermelon);
  margin-bottom: 5px;
}
.react-modal.image-upload-modal .file-drop-zone .error-msg {
  color: var(--watermelon);
  font-size: 12px;
  margin-bottom: 10px;
}
.react-modal.image-upload-modal .file-drop-zone .upload-statement {
  font-size: 12px;
  color: #141516;
  text-align: center;
  width: 120px;
}
.react-modal.image-upload-modal .file-drop-zone .upload-statement .upload-text {
  color: var(--dark-sky-blue);
  cursor: pointer;
}
.react-modal.image-upload-modal .file-drop-zone.can-drop {
  cursor: copy;
}
.react-modal.image-upload-modal .file-drop-zone.error {
  background-color: rgba(246, 84, 95, 0.1);
  border-color: var(--watermelon);
}
.react-modal.image-upload-modal .file-drop-zone.error .upload-statement {
  width: 192px;
}
.react-modal.image-upload-modal .progress-block .file-name {
  margin-bottom: 20px;
}
.react-modal.image-upload-modal .progress-block .progress-bar {
  margin-bottom: 20px;
  border: 1px solid #359ced;
  height: 12px;
  border-radius: 6px;
}
.react-modal.image-upload-modal .progress-block .progress-bar span {
  width: 0%;
  height: 100%;
  display: block;
  background-color: #359ced;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: width 2s ease;
}
.react-modal.image-upload-modal .progress-block .progress-bar.complete span {
  border-radius: 4px;
}
.react-modal.image-upload-modal .description {
  white-space: pre-line;
  font-size: 10px;
}
.react-modal.rich-image-modal .c-dialog {
  width: 500px;
}
.react-modal.rich-image-modal .rich-img-des {
  font-size: 13px;
  margin-bottom: 20px;
}
.react-modal.rich-image-modal .label-input-inline {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.react-modal.rich-image-modal .label-input-inline .label {
  min-width: 70px;
  font-size: 15px;
  margin-right: 10px;
  font-weight: 600;
  align-self: flex-start;
}
.react-modal.rich-image-modal .label-input-inline .label .must-filled {
  color: #f6545f;
}
.react-modal.rich-image-modal .label-input-inline .inputer {
  width: 100%;
}
.react-modal.rich-image-modal .label-input-inline .inputer input {
  margin-left: 0;
}
.react-modal.rich-image-modal .label-input-inline .upload-image {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.react-modal.rich-image-modal .label-input-inline .upload-image .default-img {
  width: 80px;
  height: 80px;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-modal.rich-image-modal .label-input-inline .upload-image .default-img .fa-image {
  color: #e9e9e9;
  font-size: 30px;
}
.react-modal.rich-image-modal .label-input-inline .upload-image .default-img .fa-exclamation-triangle {
  color: #f6545f;
  font-size: 30px;
}
.react-modal.rich-image-modal .label-input-inline .upload-image figure {
  width: 80px;
  height: 80px;
}
.react-modal.rich-image-modal .label-input-inline .upload-image .image-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 80px;
}
.react-modal.rich-image-modal .label-input-inline .upload-image .image-info .restriction {
  font-size: 12px;
}
.react-modal.rich-image-modal .label-input-inline .upload-image .image-info .image-name {
  font-size: 12px;
  color: #141516;
}
.react-modal.rich-image-modal .label-input-inline .upload-image .image-info .error-msg {
  color: #f6545f;
  font-size: 10px;
}
.react-modal.rich-image-modal .label-input-inline .upload-image .image-info button {
  min-width: 80px;
  padding: 0 10px;
  width: 80px;
  height: 30px;
  min-height: 30px;
  line-height: 30px;
}
.react-modal.rich-image-modal .label-input-inline .upload-image.error .default-img {
  background-color: #feedef;
  border-color: #f6545f;
}
.react-modal.rich-image-modal .label-input-inline .preview-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.react-modal.rich-image-modal .label-input-inline .preview-content .a-version {
  margin-right: 40px;
}
.react-modal.rich-image-modal .label-input-inline .preview-content .a-version figure {
  width: 150px;
  height: 150px;
  margin-top: 5px;
}
.react-modal.rich-image-modal .label-input-inline .preview-content .a-version figure.text-image {
  height: 100px;
  margin-top: 30px;
}
.react-modal.rich-image-modal .label-input-inline .date-input,
.react-modal.rich-image-modal .label-input-inline .valid-input {
  height: 30px;
  line-height: 30px;
}
.react-modal.rich-image-modal .label-input-inline .tags-inputer .c-txt__input {
  margin-left: 0;
  min-height: 30px;
  height: 30px;
  font-size: 12px;
}
.react-modal.rich-image-modal hr {
  margin: 20px 0;
  border: 1px solid #e9e9e9;
}
.react-modal.rich-image-modal .save-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.react-modal.activity-modal .c-dialog {
  width: 360px;
  max-height: 520px;
}
.react-modal.activity-modal .modal-content {
  max-height: 360px;
  overflow-y: scroll;
}
.react-modal.activity-modal .modal-content .activit-list .a-activity {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 3px;
  border: solid 1px var(--white-two);
  background-color: #ffffff;
}
.react-modal.activity-modal .modal-content .activit-list .a-activity:hover {
  background-color: var(--white-three);
}
.react-modal.activity-modal .modal-content .activit-list .a-activity:active {
  border: solid 1px var(--white-two);
  background-color: var(--white-two);
}
.react-modal.activity-modal .modal-content .activit-list .a-activity.selected {
  border: solid 1px var(--dark-sky-blue);
}
.react-modal.activity-modal .modal-content .activit-list .a-activity .activity-cover {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.react-modal.activity-modal .modal-content .activit-list .a-activity .activity-cover figure {
  width: 40px;
  height: 40px;
}
.react-modal.activity-modal .modal-content .activit-list .a-activity .activity-name-n-date .activity-name {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.36;
  color: var(--dark-grey);
}
.react-modal.activity-modal .modal-content .activit-list .a-activity .activity-name-n-date .activity-date {
  font-size: 12px;
  line-height: 1.33;
  color: var(--cool-grey);
}
.react-modal.activity-modal .button-section {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.react-modal.activity-modal .button-section button {
  width: 140px;
}
.react-modal.tag-control-modal .c-dialog {
  width: 360px;
  display: flex;
  padding: 30px 15px;
}
.react-modal.tag-control-modal .c-dialog .c-dialog__close {
  top: 5px;
  right: 5px;
}
.react-modal.tag-control-modal .modal-title {
  font-size: 16px;
  color: var(--slate-grey);
  white-space: pre-line;
  text-align: center;
  font-weight: 600;
}
.react-modal.tag-control-modal .confirm-button {
  width: 140px;
  align-self: center;
}
