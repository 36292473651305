.mobile-menu {
  color: var(--slate-grey);
}
.mobile-menu .mobile-menu-content {
  display: flex;
  flex-direction: column;
}
.mobile-menu .mobile-menu-content .mobile-menu-item {
  font-size: 16px;
  line-height: 21px;
}
.mobile-menu .mobile-menu-content .mobile-menu-item.active .text-section a {
  color: var(--dark-sky-blue);
}
.mobile-menu .mobile-menu-content .mobile-menu-item .text-section {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.mobile-menu .mobile-menu-content .mobile-menu-item .text-section a {
  text-decoration: none;
  color: var(--slate-grey);
}
.mobile-menu .mobile-menu-content .mobile-menu-item .text-section i {
  margin-left: 5px;
  line-height: 21px;
}
.mobile-menu .mobile-menu-content .mobile-menu-item .text-section:active {
  color: var(--dark-sky-blue);
}
.mobile-menu .mobile-menu-content .mobile-menu-item .child-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.mobile-menu .mobile-menu-content .mobile-menu-item .child-section a {
  text-decoration: none;
  color: var(--slate-grey);
}
.mobile-menu .mobile-menu-content .mobile-menu-item .child-section .mobile-menu-item-child {
  width: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
}
.mobile-menu .mobile-menu-content .mobile-menu-item .child-section .mobile-menu-item-child:active {
  color: var(--dark-sky-blue);
}
.mobile-menu .mobile-menu-content .mobile-menu-item .child-section .mobile-menu-item-child.active a {
  color: var(--dark-sky-blue);
}
