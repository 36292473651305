.create-new-msg .a-message {
  padding: 15px 20px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}
.create-new-msg .a-message .top-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.create-new-msg .a-message .top-section .left-section {
  display: flex;
  align-items: center;
}
.create-new-msg .a-message .top-section .left-section .filter-select {
  padding: 0 0 0 10px;
  margin-left: 20px;
}
.create-new-msg .a-message .top-section .left-section .filter-select::before {
  top: 0;
}
.create-new-msg .a-message .top-section .left-section button {
  margin-left: 20px;
  min-width: 80px;
  max-width: 80px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}
.create-new-msg .a-message .top-section .fa-trash {
  color: #aeb1b8;
  cursor: pointer;
  font-size: 12px;
}
.create-new-msg .a-message .top-section .fa-trash:hover {
  color: #f6545f;
}
.create-new-msg .a-message .select-content textarea {
  height: 100px;
  padding: 10px 15px;
  font-size: 15px;
  color: #141516;
  margin-top: 20px;
}
.create-new-msg .a-message .select-content .activity-content {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.create-new-msg .a-message .select-content .activity-content figure {
  width: 180px;
  height: 120px;
}
.create-new-msg .a-message .select-content .activity-content .text {
  font-size: 14px;
  line-height: 19px;
  color: var(--slate-grey);
  margin: 10px;
}
.create-new-msg .a-message .select-content .uploaded-file,
.create-new-msg .a-message .select-content .link-file {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.create-new-msg .a-message .select-content .uploaded-file figure,
.create-new-msg .a-message .select-content .link-file figure {
  width: 120px;
  height: 120px;
}
.create-new-msg .a-message .select-content .uploaded-file figure img,
.create-new-msg .a-message .select-content .link-file figure img {
  border-radius: 3px;
}
.create-new-msg .a-message .select-content .uploaded-file figure.preview img,
.create-new-msg .a-message .select-content .link-file figure.preview img {
  border: 1px solid var(--white-two);
  border-radius: 3px;
}
.create-new-msg .a-message .select-content .uploaded-file .media-icon,
.create-new-msg .a-message .select-content .link-file .media-icon {
  width: 120px;
  height: 120px;
  border: 1px solid #aeb1b8;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-new-msg .a-message .select-content .uploaded-file .media-icon .fal,
.create-new-msg .a-message .select-content .link-file .media-icon .fal {
  font-size: 50px;
  color: #aeb1b8;
}
.create-new-msg .a-message .select-content .uploaded-file .file-info,
.create-new-msg .a-message .select-content .link-file .file-info {
  align-self: flex-start;
  margin-left: 15px;
  font-size: 15px;
}
.create-new-msg .a-message .select-content .uploaded-file .file-info .file-name,
.create-new-msg .a-message .select-content .link-file .file-info .file-name {
  color: var(--dark-grey);
  font-size: 14px;
  margin-bottom: 10px;
}
.create-new-msg .a-message .select-content .uploaded-file .file-info .file-ext,
.create-new-msg .a-message .select-content .link-file .file-info .file-ext,
.create-new-msg .a-message .select-content .uploaded-file .file-info .file-size,
.create-new-msg .a-message .select-content .link-file .file-info .file-size,
.create-new-msg .a-message .select-content .uploaded-file .file-info .expire-date,
.create-new-msg .a-message .select-content .link-file .file-info .expire-date {
  font-size: 12px;
  margin-bottom: 10px;
  color: var(--slate-grey);
}
.create-new-msg .tooltip {
  width: 150px;
}
.create-new-msg .more-msg-button {
  width: 150px;
  margin: 20px 0;
}
