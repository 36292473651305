.qabot-answers .msg-card {
  padding: 5px 0;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
}
.qabot-answers .msg-card .top-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.qabot-answers .msg-card .top-section .left-section {
  display: flex;
  align-items: center;
}
.qabot-answers .msg-card .top-section .left-section span {
  font-size: 12px;
}
.qabot-answers .msg-card .top-section .left-section .filter-select {
  padding: 0 0 0 10px;
  margin-left: 20px;
  width: 100px;
}
.qabot-answers .msg-card .top-section .left-section .filter-select::before {
  right: 0;
  top: 0;
}
.qabot-answers .msg-card .top-section .left-section button {
  margin-left: 20px;
  min-width: 80px;
  max-width: 80px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}
.qabot-answers .msg-card .top-section .right-section .tooltip {
  display: inline;
}
.qabot-answers .msg-card .top-section .right-section .fal {
  color: var(--cool-grey);
  font-size: 12px;
  margin-left: 15px;
  cursor: pointer;
}
.qabot-answers .msg-card .top-section .right-section .fa-arrow-up:hover,
.qabot-answers .msg-card .top-section .right-section .fa-arrow-down:hover {
  color: var(--slate-grey);
}
.qabot-answers .msg-card .top-section .right-section .fa-arrow-up:active,
.qabot-answers .msg-card .top-section .right-section .fa-arrow-down:active {
  color: var(--dark-grey);
}
.qabot-answers .msg-card .top-section .right-section .fa-arrow-up.disabled,
.qabot-answers .msg-card .top-section .right-section .fa-arrow-down.disabled {
  color: var(--white-two);
  cursor: initial;
}
.qabot-answers .msg-card .top-section .right-section .fa-comment-alt-times:hover {
  color: var(--watermelon);
}
.qabot-answers .msg-card .top-section .right-section .fa-comment-alt-times:active {
  color: var(--faded-red);
}
.qabot-answers .msg-card .top-section .right-section .fa-comment-alt-plus:hover {
  color: var(--dark-sky-blue);
}
.qabot-answers .msg-card .top-section .right-section .fa-comment-alt-plus:active {
  color: var(--bluish);
}
.qabot-answers .msg-card .top-section .right-section .fa-comment-alt-plus.disabled {
  color: var(--white-two);
  cursor: initial;
}
.qabot-answers .msg-card .select-content textarea {
  min-height: 30px;
  height: 30px;
  padding: 5px 10px;
  font-size: 13px;
  color: var(--black);
  margin-top: 5px;
}
.qabot-answers .msg-card .select-content .uploaded-file,
.qabot-answers .msg-card .select-content .link-file {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.qabot-answers .msg-card .select-content .uploaded-file figure,
.qabot-answers .msg-card .select-content .link-file figure {
  width: 50px;
  height: 50px;
}
.qabot-answers .msg-card .select-content .uploaded-file figure img,
.qabot-answers .msg-card .select-content .link-file figure img {
  border-radius: 3px;
}
.qabot-answers .msg-card .select-content .uploaded-file figure.preview img,
.qabot-answers .msg-card .select-content .link-file figure.preview img {
  border: 1px solid var(--white-two);
}
.qabot-answers .msg-card .select-content .uploaded-file .media-icon,
.qabot-answers .msg-card .select-content .link-file .media-icon {
  width: 50px;
  height: 50px;
  border: 1px solid var(--cool-grey);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qabot-answers .msg-card .select-content .uploaded-file .media-icon .fal,
.qabot-answers .msg-card .select-content .link-file .media-icon .fal {
  font-size: 20px;
  color: var(--cool-grey);
}
.qabot-answers .msg-card .select-content .uploaded-file .file-info,
.qabot-answers .msg-card .select-content .link-file .file-info {
  margin-left: 10px;
  font-size: 13px;
}
.qabot-answers .msg-card .select-content .uploaded-file .file-info .file-name,
.qabot-answers .msg-card .select-content .link-file .file-info .file-name {
  font-size: 13px;
  color: var(--dark-grey);
}
.qabot-answers .msg-card .select-content .uploaded-file .file-info .file-ext-n-size,
.qabot-answers .msg-card .select-content .link-file .file-info .file-ext-n-size {
  font-size: 12px;
}
.qabot-answers .msg-card:last-child {
  border: 0;
}
