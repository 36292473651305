.chat-history {
  width: 100%;
  padding-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.chat-history .histories {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  bottom: 60px;
  overflow-y: auto;
}
.chat-history .message-input {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 20px 30px 0 30px;
  position: absolute;
  width: calc(100% - 60px);
  bottom: 10px;
}
.chat-history .message-input .add-media-msg {
  position: relative;
}
.chat-history .message-input .add-media-msg .add-button {
  width: 40px;
  height: 40px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #e9e9e9;
}
.chat-history .message-input .add-media-msg .add-button .fa-plus {
  font-size: 20px;
  color: #141516;
}
.chat-history .message-input .add-media-msg .add-button:hover {
  background-color: #e9e9e9;
}
.chat-history .message-input .add-media-msg .add-button.show-menu {
  background-color: transparent;
}
.chat-history .message-input .add-media-msg .add-button.show-menu .fa-plus {
  color: #e9e9e9;
}
.chat-history .message-input .add-media-msg .media-menu {
  position: absolute;
  top: -130px;
  left: 0;
  width: 130px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.chat-history .message-input .add-media-msg .media-menu .a-row {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aeb1b8;
  font-size: 16px;
  cursor: pointer;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
}
.chat-history .message-input .add-media-msg .media-menu .a-row:hover {
  color: #359ced;
}
.chat-history .message-input .add-media-msg .media-menu .a-row:last-child {
  border: 0;
}
.chat-history .message-input .c-txt__input {
  height: auto;
  max-height: 100px;
  margin-left: 10px;
  background-color: transparent;
}
.chat-history .message-input .c-txt__input textarea {
  resize: none;
  max-height: 100px;
  height: 38px;
  padding-top: 10px;
  padding-right: 10px;
}
.chat-history .message-input .c-txt__input .fa-paper-plane {
  cursor: pointer;
}
.chat-history .message-input .c-txt__input .fa-paper-plane:hover {
  color: #359ced;
}
.chat-history.show-drop-zone .drop-zone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chat-history.show-drop-zone .drop-zone .fa-cloud-upload {
  font-size: 60px;
  color: #359ced;
}
.chat-history.show-drop-zone .drop-zone span {
  margin-top: 5px;
  font-size: 22px;
}
.chat-history .upload-block {
  position: absolute;
  bottom: 65px;
  left: 82px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .chat-history .message-input {
    margin: 10px 20px 0 20px;
    width: calc(100% - 40px);
    bottom: 70px;
  }
  .chat-history .histories {
    bottom: 120px;
  }
}
