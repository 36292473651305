.message-center-right {
  display: flex;
  flex-direction: column;
  width: calc(100% - 400px);
  height: 100%;
}
.message-center-right .mc-header {
  width: 100%;
  display: flex;
  height: 100px;
  flex-direction: row;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
  align-items: center;
}
.message-center-right .mc-header .user-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.message-center-right .mc-header .user-header .user-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 25px;
}
.message-center-right .mc-header .user-header .user-avatar .mobile-back-to-list {
  display: none;
  padding-left: 7px;
  font-size: 16px;
  height: 100%;
  padding-right: 10px;
}
.message-center-right .mc-header .user-header .user-avatar figure {
  width: 60px;
  height: 60px;
}
.message-center-right .mc-header .user-header .user-avatar figure img {
  border-radius: 50%;
}
.message-center-right .mc-header .user-header .user-avatar .user-avatar-name-n-owner {
  margin-left: 20px;
}
.message-center-right .mc-header .user-header .user-avatar .user-owner-select {
  margin-top: 5px;
}
.message-center-right .mc-header .user-header .user-avatar .user-owner-select .user-owner-select-input {
  height: 35px;
  min-height: 35px;
  line-height: 35px;
  padding: 0 10px;
  font-size: 14px;
}
.message-center-right .mc-header .user-header .user-avatar .user-owner-select .user-owner-select-input.disabled {
  color: #b9b9b0;
  cursor: default;
}
.message-center-right .mc-header .user-header .user-avatar .user-owner-select .user-owner-select-input::before {
  top: 0;
}
.message-center-right .mc-header .user-header .user-control {
  height: 60px;
  display: flex;
  align-items: center;
}
.message-center-right .mc-header .user-header .user-control .button {
  cursor: pointer;
  border: 1px solid var(--cool-grey);
  border-radius: 4px;
  font-size: 13px;
  color: var(--cool-grey);
  padding: 4px 10px;
  margin-right: 15px;
}
.message-center-right .mc-header .user-header .user-control .button.active {
  color: var(--dark-sky-blue);
  border: 1px solid var(--dark-sky-blue);
}
.message-center-right .mc-header .user-header .user-control .button:last-child {
  margin-right: 50px;
}
.message-center-right .mc-header .user-header .user-control .input-checkbox-block {
  margin-right: 30px;
}
.message-center-right .mc-header .user-header .user-control .input-checkbox-block label {
  font-size: 13px;
  color: var(--slate-grey);
}
.message-center-right .mc-header .user-header .user-control .fa-ellipsis-v {
  font-size: 25px;
  margin-right: 50px;
}
.message-center-right .mc-header .user-header .user-control .fa-ellipsis-v.active {
  color: #359ced;
}
.message-center-right .mc-content {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.message-center-right .mc-content .user-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: inherit;
}
.message-center-right .mc-content .user-section .chat-history {
  flex-grow: 1;
  overflow: auto;
  height: 100%;
}
@media only screen and (min-width: 769px) and (max-width: 1366px) {
  .message-center-right {
    width: calc(100% - 280px);
  }
  .message-center-right .mc-header {
    height: 90px;
    justify-content: center;
  }
  .message-center-right .mc-header .user-header {
    width: 100%;
  }
  .message-center-right .mc-header .user-header .user-control .button:last-child {
    margin-right: 20px;
  }
  .message-center-right .mc-content .user-section {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .message-center-right {
    display: none;
    width: 100%;
    min-width: 250px;
  }
  .message-center-right.mobile-show {
    display: flex;
    position: fixed;
    left: 0;
    background-color: #fafafb;
  }
  .message-center-right .mc-header {
    height: auto;
    justify-content: center;
  }
  .message-center-right .mc-header .user-header {
    flex-direction: column;
  }
  .message-center-right .mc-header .user-header .user-avatar {
    padding-left: 10px;
    width: calc(100% - 20px);
    margin: 0;
    padding-top: 15px;
  }
  .message-center-right .mc-header .user-header .user-avatar .mobile-back-to-list {
    display: flex;
  }
  .message-center-right .mc-header .user-header .user-avatar .user-avatar-name-n-owner {
    margin-top: 5px;
  }
  .message-center-right .mc-header .user-header .user-avatar .user-avatar-name-n-owner .user-owner-select .user-owner-select-input {
    height: 25px;
    min-height: 25px;
    line-height: 25px;
  }
  .message-center-right .mc-header .user-header .user-control {
    height: auto;
    flex-wrap: wrap;
    padding: 15px 10px;
  }
  .message-center-right .mc-header .user-header .user-control .button:last-child {
    margin: 0;
  }
  .message-center-right .mc-content .user-section {
    width: 100%;
  }
}
