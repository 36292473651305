#keyord-add-wrap {
  width: 280px;
  padding: 10px 0;
}
#keyord-add-wrap .keyword-add-modal {
  min-height: 200px;
  text-align: center;
}
#keyord-add-wrap .keyword-add-modal button {
  width: calc(100% - 20px);
}
#keyord-add-wrap .keyword-add-modal input {
  width: calc(100% - 20px);
  height: 30px;
  border-radius: 3px;
  border: 1px solid var(--white-two);
  color: var(--dark-grey);
  padding-left: 10px;
  margin: 0 auto;
}
#keyord-add-wrap .keyword-add-modal .dir-list {
  min-height: 120px;
  max-height: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0 10px;
}
#keyord-add-wrap .keyword-add-modal .dir-list .not-create-tag {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}
#keyord-add-wrap .keyword-add-modal .dir-list.have-button {
  max-height: 120px;
}
#keyord-add-wrap .keyword-add-modal .dir-list.not-mac::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--white-two);
  -webkit-border-radius: 100px;
  margin-right: 1px;
}
#keyord-add-wrap .keyword-add-modal .dir-list.not-mac::-webkit-scrollbar:hover {
  background-color: var(--white-two);
}
#keyord-add-wrap .keyword-add-modal .dir-list.not-mac::-webkit-scrollbar-thumb {
  background: var(--white-two);
  -webkit-border-radius: 100px;
}
#keyord-add-wrap .keyword-add-modal .dir-list.not-mac::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 100px;
}
