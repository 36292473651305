.console-create-channel {
  min-width: 950px;
}
.console-create-channel .page-header {
  padding: 10px 30px;
}
.set-name-photo-block {
  padding: 0 30px;
}
.set-name-photo-block .channel-name-and-photo {
  color: #141516;
  font-size: 18px;
}
.set-name-photo-block .channel-photo-des {
  padding: 15px 0;
  font-size: 13px;
}
.set-name-photo-block .channel-photo-des a {
  text-decoration: unset;
  margin: 0 5px;
}
.set-name-photo-block .channel-info-edit {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}
.set-name-photo-block .channel-info-edit .image-upload {
  width: 70px;
  height: 70px;
  background-color: #f7d767;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.set-name-photo-block .channel-info-edit .image-upload figure {
  width: 70px;
  height: 70px;
}
.set-name-photo-block .channel-info-edit .image-upload figure img {
  border-radius: 50%;
}
.set-name-photo-block .channel-info-edit .image-upload .camera-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  right: 0;
  bottom: 0;
}
.set-name-photo-block .channel-info-edit .image-upload .camera-icon .fal {
  font-size: 12px;
  color: #aeb1b8;
}
.set-name-photo-block .channel-info-edit .channel-name {
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.set-name-photo-block .channel-info-edit .channel-name .valid-input {
  width: 100%;
}
.set-name-photo-block .channel-info-edit .channel-name .valid-input:before {
  top: 12px;
}
.set-name-photo-block .save-n-error {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.set-name-photo-block .save-n-error button {
  width: 150px;
}
.set-name-photo-block .save-n-error .error {
  margin-left: 10px;
  color: #f6545f;
}
