.a-upload-card {
  max-width: 230px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 0 10px 5px 0;
}
.a-upload-card figure {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.a-upload-card figure img {
  object-fit: cover;
}
.a-upload-card figure.preview img {
  border: 1px solid var(--white-two);
  border-radius: 3px;
}
.a-upload-card .file-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.a-upload-card .file-icon .fal {
  font-size: 30px;
}
.a-upload-card .file-name-n-progress-bar {
  margin-right: 10px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.a-upload-card .file-name-n-progress-bar .file-name {
  font-size: 12px;
  margin-bottom: 5px;
}
.a-upload-card .file-name-n-progress-bar .error-msg {
  color: var(--watermelon);
  font-size: 12px;
}
.a-upload-card .file-name-n-progress-bar .progress-bar {
  border: 1px solid #359ced;
  height: 6px;
  border-radius: 3px;
}
.a-upload-card .file-name-n-progress-bar .progress-bar span {
  width: 0%;
  height: 100%;
  display: block;
  background-color: #359ced;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  transition: width 2s ease;
}
.a-upload-card .file-name-n-progress-bar .progress-bar.complete span {
  border-radius: 1px;
}
.a-upload-card .close-button {
  cursor: pointer;
}
.a-upload-card .close-button .fal {
  font-size: 14px;
  color: var(--cool-grey);
}
.a-upload-card .close-button .fal:hover {
  color: var(--watermelon);
}
.a-upload-card.is-error .file-icon .fa-exclamation-triangle {
  color: var(--watermelon);
}
.a-upload-card.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
.a-upload-card.hidden {
  display: none;
  height: 0;
  width: 0;
}
