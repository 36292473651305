.line-activity {
  display: flex;
  flex-direction: column;
  background-color: var(--pale-grey);
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.line-activity .line-button {
  max-width: 180px;
  height: 40px;
  flex-grow: 1;
  background-color: #359ced;
  color: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  font-size: 16px;
  margin-right: 20px;
}
.line-activity .line-button:last-child {
  margin-right: 0px;
}
.line-activity .line-button.disabled {
  background-color: #e9e9e9;
  color: #c2c8cc;
}
.line-activity .line-button.close-button {
  background-color: #e9e9e9;
  color: #606972;
}
.line-activity .button-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.line-activity .normal-activity {
  flex-direction: column;
  background-color: var(--pale-grey);
  align-items: center;
  margin-top: 20px;
}
.line-activity .normal-activity .activity-name {
  font-size: 16px;
  margin-bottom: 10px;
}
.line-activity .normal-activity .line-button {
  margin-top: 20px;
  width: 100%;
  background-color: #00B900;
}
.line-activity .vote-activity {
  margin: 20px 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line-activity .vote-activity .vote-state {
  display: flex;
  font-size: 16px;
  color: var(--cool-grey);
}
.line-activity .vote-activity .vote-state.ongoing {
  color: #f6545f;
}
.line-activity .vote-activity .vote-name {
  display: flex;
  font-size: 25px;
  color: #141516;
  font-weight: 600;
  margin-top: 15px;
}
.line-activity .vote-activity .channel-avatar-and-name {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--slate-grey);
}
.line-activity .vote-activity .channel-avatar-and-name .channel-avatar {
  width: 40px;
  height: 40px;
  background-color: #f7d767;
  border-radius: 50%;
  margin-right: 15px;
}
.line-activity .vote-activity .channel-avatar-and-name .channel-avatar figure {
  width: 40px;
  height: 40px;
}
.line-activity .vote-activity .channel-avatar-and-name .channel-avatar figure img {
  border-radius: 50%;
}
.line-activity .vote-activity .channel-avatar-and-name .channel-name {
  font-size: 16px;
  color: var(--cool-grey);
}
.line-activity .vote-activity .vote-choose {
  width: 100%;
  flex-direction: column;
}
.line-activity .vote-activity .vote-choose .vote-selection-type {
  color: #f6545f;
  font-size: 12px;
}
.line-activity .vote-activity .vote-choose .vote-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line-activity .vote-activity .vote-result {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line-activity .vote-activity .vote-result .vote-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line-activity .vote-activity .vote-result .vote-hint {
  margin-top: 10px;
}
.line-activity .vote-activity .vote-expired {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line-activity .vote-activity .vote-expired .vote-expired-figure {
  margin-top: 35px;
}
.line-activity .vote-activity .vote-expired .vote-expired-reply {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
}
.line-activity .vote-activity .vote-not-start {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line-activity .vote-activity .vote-not-start .vote-not-start-figure {
  margin-top: 35px;
}
.line-activity .vote-activity .vote-not-start .vote-not-start-text {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
.line-activity .vote-activity .vote-not-start .vote-not-start-text .vote-not-start-date {
  display: flex;
}
.line-activity .vote-activity .vote-not-start .vote-not-start-text .vote-not-start-date .date-text {
  color: var(--dark-sky-blue);
}
.a-vote-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 15px;
  width: 300px;
  min-height: 40px;
  border: solid 1px var(--white-two);
  background-color: #ffffff;
}
.a-vote-option.disabled {
  background-color: var(--white-two);
}
.a-vote-option.disabled label,
.a-vote-option.disabled span {
  color: var(--dark-grey);
}
.a-vote-option.disabled.is-select {
  background-color: var(--ice-blue);
}
.a-vote-option.disabled.is-select .input-section input[type="checkbox"]:checked {
  border-color: var(--cool-grey);
}
.a-vote-option.disabled.is-select .input-section input[type="checkbox"]:checked::after {
  color: var(--cool-grey);
}
.a-vote-option.disabled.is-select .input-section input[type="radio"]:checked {
  border-color: var(--cool-grey);
}
.a-vote-option.disabled.is-select .input-section input[type="radio"]:checked::after {
  background-color: var(--cool-grey);
}
.a-vote-option.single-select {
  border-radius: 23px;
}
.a-vote-option.multi-select {
  border-radius: 3px;
}
.a-vote-option:active {
  border: solid 1px var(--dark-sky-blue);
}
.a-vote-option.is-select {
  background-color: var(--ice-blue);
  border: solid 1px var(--ice-blue);
}
.a-vote-option .input-section {
  display: flex;
}
.a-vote-option .input-section .input-radio-block,
.a-vote-option .input-section .input-checkbox-block {
  margin-bottom: 0px;
}
.a-vote-option .input-section .input-radio-block label,
.a-vote-option .input-section .input-checkbox-block label,
.a-vote-option .input-section .input-radio-block span,
.a-vote-option .input-section .input-checkbox-block span {
  font-size: 18px;
  padding: 8px 0;
  max-width: 240px;
}
.a-vote-option .count-section {
  font-size: 16px;
  line-height: 1.31;
  display: flex;
  flex-direction: column;
}
