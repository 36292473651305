.console-root .console-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.console-root .console-content .side {
  flex: 0 0 80px;
}
.console-root .console-content .main-content {
  flex: 0 0 calc(100% - 80px);
  justify-content: center;
  overflow-x: auto;
}
@media only screen and (min-width: 769px) and (max-width: 1366px), only screen and (max-width: 768px) {
  .console-root .console-content .side {
    flex: 0 0 60px;
  }
  .console-root .console-content .main-content {
    flex: 0 0 calc(100% - 60px);
  }
}
