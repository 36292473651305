.all-keyword {
  margin-bottom: 10px;
}
.all-keyword .a-keyword-section {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--white-two);
  background-color: var(--white-three);
  margin-bottom: 5px;
}
.all-keyword .a-keyword-section.level-1 {
  margin-left: 25px;
  width: calc(100% - 25px);
}
.all-keyword .a-keyword-section .title {
  display: flex;
}
.all-keyword .a-keyword-section .tag-section {
  margin: 10px 0 ;
  display: flex;
}
.all-keyword .a-keyword-section .tag-section .tag {
  padding: 3px 10px;
  margin-right: 5px;
  border-radius: 2px;
  background-color: var(--ice-blue);
}
.all-keyword .a-keyword-section .indent {
  margin: 0 7px;
  padding-top: 15px;
}
.all-keyword .a-keyword-section .indent figure {
  width: 16px;
  height: 16px;
}
.all-keyword .a-keyword-section .indent figure img {
  object-fit: contain;
}
.all-keyword .a-keyword-section .indent .fa-ellipsis-v {
  width: 16px;
  color: var(--cool-grey);
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}
.all-keyword .a-keyword-section .sub-keyword-section {
  display: flex;
  border-top: var(--white-two);
  border-top-width: 1px;
  border-top-style: solid;
  padding-left: 25px;
}
