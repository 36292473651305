.tooltip .__react_component_tooltip {
  padding: 5px;
  white-space: pre-line;
}
.tooltip .__react_component_tooltip.type-light {
  border: solid 1px var(--white-three);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-top: 0;
  color: var(--slate-grey);
}
.tooltip .__react_component_tooltip.place-left {
  margin-top: 30px;
}
.tooltip .__react_component_tooltip.icon-title {
  background-color: var(--ice-blue-two);
  color: var(--gunmetal);
  padding: 3px;
  border: 0;
  box-shadow: initial;
}
.tooltip .__react_component_tooltip:before,
.tooltip .__react_component_tooltip:after {
  border: 0;
}
