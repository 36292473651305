.console-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 2;
}
.console-header .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}
.console-header .logo figure {
  width: 60px;
  height: 60px;
}
.console-header .logo figure img {
  object-fit: initial;
}
.console-header .right-part {
  display: flex;
  align-items: center;
}
.console-header .right-part .noti-icon {
  margin-right: 30px;
  position: relative;
}
.console-header .right-part .noti-icon .fa-bell {
  color: var(--dark-sky-blue);
  font-size: 18px;
  cursor: pointer;
}
.console-header .right-part .noti-icon .dot {
  position: absolute;
  content: " ";
  width: 15px;
  height: 15px;
  border: solid 1px #ffffff;
  background-color: var(--watermelon);
  color: #ffffff;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  display: block;
  font-size: 12px;
  text-align: center;
  cursor: default;
}
.console-header .right-part .noti-icon:focus {
  outline: 0;
}
.console-header .right-part .user-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 50px;
  cursor: pointer;
}
.console-header .right-part .user-avatar .c-avatar {
  margin-right: 10px;
}
.console-header .right-part .user-avatar .c-avatar img {
  max-width: 40px;
  max-height: 40px;
}
.console-header .right-part .user-avatar .c-avatar.default img {
  background-color: #359ced;
}
.console-header .right-part .user-avatar span {
  margin-right: 10px;
}
@media only screen and (min-width: 769px) and (max-width: 1366px), only screen and (max-width: 768px) {
  .console-header {
    height: 60px;
  }
  .console-header .logo {
    height: 60px;
    width: 60px;
  }
  .console-header .logo figure {
    width: 40px;
    height: 40px;
  }
  .console-header .right-part .user-avatar {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .console-header .right-part .user-avatar figure {
    display: none;
  }
}
#noti-container {
  margin-top: 5px;
  padding: 0;
}
#noti-container .noti-list {
  max-height: 305px;
  overflow: auto;
  width: 300px;
}
#noti-container .noti-list .noti-status {
  color: var(--gunmetal);
  padding: 10px 0;
  border: solid var(--white-two);
  display: flex;
  align-items: center;
  justify-content: center;
}
#noti-container .noti-list .noti-status:first-child {
  border-width: 0 0 1px 0;
}
#noti-container .noti-list .noti-status:last-child {
  border-width: 1px 0 0 0;
}
#noti-container .noti-list .a-notification {
  margin: 0 15px;
  padding: 15px 0;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  text-decoration: unset;
}
#noti-container .noti-list .a-notification figure {
  width: 30px;
  height: 30px;
}
#noti-container .noti-list .a-notification figure img {
  border-radius: 50%;
}
#noti-container .noti-list .a-notification figure .read-cover {
  display: none;
}
#noti-container .noti-list .a-notification .noti-info {
  margin-left: 10px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}
#noti-container .noti-list .a-notification .noti-info .action {
  line-height: 30px;
  vertical-align: middle;
  font-size: 14px;
  color: var(--dark-grey);
}
#noti-container .noti-list .a-notification .noti-info .channel-name-n-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#noti-container .noti-list .a-notification .noti-info .channel-name-n-time .channel-name {
  font-size: 12px;
  color: var(--gunmetal);
}
#noti-container .noti-list .a-notification .noti-info .channel-name-n-time .noti-time {
  font-size: 12px;
  color: var(--gunmetal);
}
#noti-container .noti-list .a-notification.is-read figure {
  position: relative;
}
#noti-container .noti-list .a-notification.is-read figure .read-cover {
  position: absolute;
  display: block;
  background-color: rgba(174, 177, 184, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  border-radius: 50%;
}
#noti-container .noti-list .a-notification.is-read .noti-info .action {
  color: var(--cool-grey);
}
#noti-container .noti-list .a-notification.is-read .noti-info .channel-name-n-time .channel-name {
  color: var(--cool-grey);
}
#noti-container .noti-list .a-notification.is-read .noti-info .channel-name-n-time .noti-time {
  color: var(--cool-grey);
}
#noti-container .noti-list .a-notification.is-last {
  border: 0;
}
