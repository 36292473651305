.tags-inputer {
  width: 100%;
  position: relative;
}
.tags-inputer .c-txt__input {
  width: 100%;
  color: var(--dark-grey);
  font-size: 13px;
}
.tags-inputer .tags-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5px;
}
.tags-inputer .tags-section .tag-with-count {
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
}
.tags-inputer .tags-section .tag-with-count .tag-value {
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-grey);
  padding: 5px 5px 5px 8px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.tags-inputer .tags-section .tag-with-count .tag-value.level-0 {
  background-color: #f4fafe;
}
.tags-inputer .tags-section .tag-with-count .tag-value.level-1 {
  background-color: #eaf5fd;
}
.tags-inputer .tags-section .tag-with-count .tag-value.level-2 {
  background-color: #e1f0fd;
}
.tags-inputer .tags-section .tag-with-count .tag-value.level-3 {
  background-color: #cce6fb;
}
.tags-inputer .tags-section .tag-with-count .tag-value.level-4 {
  background-color: #b8dcf9;
}
.tags-inputer .tags-section .tag-with-count .tag-value.level-5 {
  background-color: #a4d2f7;
}
.tags-inputer .tags-section .tag-with-count .tag-value.level-6 {
  background-color: #85c3f4;
}
.tags-inputer .tags-section .tag-with-count .count-part {
  background-color: white;
  display: flex;
  flex-direction: row;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-value {
  background-color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: var(--slate-grey);
  border: none;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 10px 5px 5px;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-value-input input {
  background: transparent;
  border: none;
  display: flex;
  flex-grow: 1;
  min-width: 8px;
  color: var(--slate-grey);
  font-size: 12px;
  line-height: 16px;
  padding: 5px;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-value-input input:focus {
  outline: 0;
}
.tags-inputer .tags-section .tag-with-count .count-part .tag-delete-icon-list {
  display: flex;
  justify-content: center;
  padding: 5px 10px 5px 0px;
}
.tags-inputer .tags-section .tag-with-count .count-part .tag-delete-icon-list .fa-times {
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  color: var(--cool-grey);
}
.tags-inputer .tags-section .tag-with-count .count-part .tag-delete-icon-list .fa-times:hover {
  color: var(--slate-grey);
}
.tags-inputer .tags-section .tag-with-count .count-part .tag-delete-icon-list .fa-trash {
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  color: #ff5556;
}
.tags-inputer .tags-section .tag-with-count .count-part .tag-delete-icon-list .is-hide {
  display: none;
}
.tags-inputer .tags-section .tag-with-count .count-part .tag-delete-icon-list:focus {
  outline: 0;
}
.tags-inputer .tags-section .tag-with-count .count-part .tag-delete-icon-list.is-hide {
  display: none;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list {
  display: flex;
  flex-direction: column;
  padding-right: 7px;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list .icon-sort-up {
  margin-top: 4px;
  padding-right: 3px;
  height: 6px;
  display: flex;
  justify-content: center;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list .icon-sort-up .fa-sort-up {
  color: var(--cool-grey);
  font-size: 10px;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list .icon-sort-up:hover {
  cursor: pointer;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list .icon-sort-up:hover .fa-sort-up {
  color: var(--slate-grey);
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list .icon-sort-down {
  margin-top: 1px;
  padding-right: 3px;
  display: flex;
  justify-content: center;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list .icon-sort-down .fa-sort-down {
  color: var(--cool-grey);
  font-size: 10px;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list .icon-sort-down:hover {
  cursor: pointer;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list .icon-sort-down:hover .fa-sort-down {
  color: var(--slate-grey);
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list.is-hide {
  display: none;
}
.tags-inputer .tags-section .tag-with-count .count-part .count-edit-icon-list:focus {
  outline: 0;
}
.tags-inputer .tags-section .tag-with-count .count-part .add-icon {
  display: flex;
  justify-content: center;
  padding: 5px 10px 5px 0px;
}
.tags-inputer .tags-section .tag-with-count .count-part .add-icon .fa-plus,
.tags-inputer .tags-section .tag-with-count .count-part .add-icon .fa-check {
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  color: var(--cool-grey);
}
.tags-inputer .tags-section .tag-with-count .count-part.is-hide {
  display: none;
}
.tags-inputer .tags-section .tag-with-count .count-part:focus {
  outline: 0;
}
.tags-inputer .tags-section .tag-plus-button {
  margin-right: 10px;
  margin-top: 5px;
}
.tags-inputer .filter-tag-n-count {
  margin-top: 10px;
}
.tags-inputer .filter-tag-n-count .tag-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.tags-inputer .filter-tag-n-count .tag-filter span {
  color: var(--dark-grey);
  font-size: 14px;
}
.tags-inputer .filter-tag-n-count .tag-filter .a-tag {
  padding: 3px 10px;
  color: var(--dark-grey);
  margin-left: 10px;
  background-color: var(--ice-blue);
  border-radius: 3px;
  height: 25px;
}
.tags-inputer .filter-tag-n-count .tag-filter .compare-select {
  height: 25px;
  margin-left: 10px;
  padding: 0 0 0 10px;
  line-height: 25px;
  width: 100px;
}
.tags-inputer .filter-tag-n-count .tag-filter .compare-select::before {
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
}
.tags-inputer .filter-tag-n-count .tag-filter .count-inputer {
  margin-left: 10px;
}
.tags-inputer .filter-tag-n-count .tag-filter .count-inputer input {
  min-width: 50px;
  font-size: 14px;
  color: var(--dark-grey);
  min-height: 25px;
  height: 25px;
  text-align: center;
  padding: 0 10px;
}
.tags-inputer .filter-tag-n-count .tag-filter .fa-times {
  color: var(--cool-grey);
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}
.tags-inputer .filter-tag-n-count .tag-filter .fa-times:hover {
  color: var(--watermelon);
}
.tags-inputer .filter-tag-n-count .tag-filter .fa-times:active {
  color: var(--faded-red);
}
.tags-inputer .filter-tag-n-count .tag-filter:last-child {
  margin-bottom: 0;
}
