.service-root {
  position: fixed;
  width: 100%;
  height: 100%;
}
.service-root.not-mac ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}
.service-root.not-mac ::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
.service-root.not-mac ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
}
.service-root.not-mac ::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 100px;
}
