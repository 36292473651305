.add-keyword-block {
  min-height: 100px;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  background-color: #ffffff;
  padding: 10px;
}
.add-keyword-block .input-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.add-keyword-block .input-section .plus-button {
  margin: 0 5px 5px 0;
}
.add-keyword-block .input-section span.placeholder {
  font-size: 13px;
  color: var(--black);
  margin: 0 5px 5px 0;
}
.add-keyword-block .input-section .a-tag {
  max-width: 100%;
  height: 24px;
  background-color: var(--ice-blue);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px 5px 0;
  color: var(--black);
  font-size: 12px;
  padding: 0 3px 0 8px;
}
.add-keyword-block .input-section .a-tag span {
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  color: var(--black);
}
.add-keyword-block .input-section .a-tag .fa-times {
  width: 14px;
  margin-left: 5px;
  cursor: pointer;
  color: var(--cool-grey);
  line-height: normal;
}
.add-keyword-block .input-section .a-tag .fa-times:hover {
  color: var(--watermelon);
}
.add-keyword-block .input-section .a-tag .fa-times:active {
  color: var(--faded-red);
}
