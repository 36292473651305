.user-data {
  min-width: 300px;
  max-width: 300px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.user-data .user-tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--white-three);
  border: solid var(--white-two);
  border-width: 1px 0 1px 0;
}
.user-data .user-tabs .a-tab {
  margin-right: 15px;
}
.user-data .user-tabs .a-tab .fal {
  color: var(--cool-grey);
  cursor: pointer;
}
.user-data .user-tabs .a-tab .fal:hover {
  color: var(--gunmetal);
}
.user-data .user-tabs .a-tab .fal.active {
  color: var(--dark-sky-blue);
  cursor: initial;
}
.user-data .user-tab-content {
  height: calc(100% - 40px);
  overflow-y: scroll;
  overflow-x: inherit;
}
@media (max-width: 1366px) {
  .user-data {
    min-width: 280px;
    max-width: 280px;
  }
}
