.default-page {
  margin: 0 auto;
  padding: 0 30px 65px 30px;
}
.default-page .bot-setting-content .button-group {
  margin-top: 30px;
}
.default-page .bot-setting-content .setting-part textarea {
  margin-bottom: 25px;
  height: 100px;
}
.default-page .bot-setting-content .setting-part .go-to-one-to-one {
  font-size: 16px;
}
.default-page .bot-setting-content .setting-part .go-to-one-to-one a {
  color: var(--dark-sky-blue);
  text-decoration: unset;
  margin: 0 5px;
}
