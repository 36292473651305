.file-download {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.file-download .download-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file-download .download-status figure img {
  object-fit: initial;
}
.file-download .download-status h3 {
  font-size: 22px;
  color: var(--slate-grey);
  margin-top: 50px;
}
.file-download .download-status .description {
  margin-top: 20px;
  font-size: 16px;
  color: var(--cool-grey);
  white-space: pre;
  text-align: center;
}
.file-download .download-status .button-group {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-download .download-status .button-group button {
  width: 180px;
  margin: 0 10px;
}
.file-download .download-status .button-group .know-more-button {
  width: 180px;
}
.file-download .download-tutorial {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.file-download .download-tutorial img {
  width: 100%;
}
@media only screen and (max-width: 420px) {
  .file-download .download-status .button-group button {
    width: 120px;
    padding: 0 10px;
  }
}
