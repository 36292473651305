.activity-vote-setting .vote-option {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}
.activity-vote-setting .vote-option .vote-option-index {
  margin-right: 10px;
  font-size: 14px;
  line-height: 40px;
}
.activity-vote-setting .vote-option .vote-option-value-section {
  flex-grow: 1;
}
.activity-vote-setting .vote-option .vote-option-value-section .vote-option-edit-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.activity-vote-setting .vote-option .vote-option-value-section .vote-option-edit-section .vote-option-input {
  flex-grow: 1;
  padding-right: 10px;
}
.activity-vote-setting .vote-option .vote-option-value-section .vote-option-edit-section .vote-option-icon-list {
  padding: 5px 10px 5px 0px;
}
.activity-vote-setting .vote-option .vote-option-value-section .vote-option-edit-section .vote-option-icon-list i {
  line-height: 30px;
  width: 10px;
}
.activity-vote-setting .vote-option .vote-option-value-section .vote-option-edit-section .vote-option-icon-list i.fa-trash {
  color: #ff5556;
}
.activity-vote-setting .vote-option .date-input {
  width: 200px;
  height: 35px;
}
