.filter-block {
  padding: 15px 20px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}
.filter-block .top-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filter-block .top-section .left {
  display: flex;
  flex-direction: row;
}
.filter-block .top-section .left .filter-select {
  padding: 0 0 0 10px;
}
.filter-block .top-section .left .filter-select::before {
  top: 0;
  right: 0;
}
.filter-block .top-section .left .include-select {
  padding: 0 0 0 10px;
  margin-left: 10px;
  width: 100px;
}
.filter-block .top-section .left .include-select::before {
  top: 0;
  right: 0;
}
.filter-block .top-section .fa-trash {
  color: #aeb1b8;
  cursor: pointer;
  font-size: 12px;
}
.filter-block .top-section .fa-trash:hover {
  color: #f6545f;
}
.filter-block .select-content .checkbox-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.filter-block .select-content .checkbox-list .input-checkbox-block {
  margin-right: 20px;
}
.filter-block .select-content .travel-area {
  height: 150px;
  overflow-y: scroll;
}
.filter-block .select-content .travel-area .a-area {
  margin-bottom: 15px;
}
.filter-block .select-content .travel-area .a-area input[type="checkbox"] {
  width: 12px;
  height: 12px;
}
.filter-block .select-content .travel-area .a-area input[type="checkbox"].partial_checked {
  border-color: #359ced;
}
.filter-block .select-content .travel-area .a-area input[type="checkbox"].partial_checked::after {
  display: block;
  content: " ";
  background-color: #359ced;
  width: 6px;
  height: 6px;
  border-radius: 1px;
  position: absolute;
  top: 2px;
  left: 2px;
}
.filter-block .select-content .travel-area .a-area input[type="checkbox"]::after {
  font-size: 12px;
}
.filter-block .select-content .travel-area .a-area .sub-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  flex-wrap: wrap;
}
.filter-block .select-content .travel-area .a-area .sub-list .a-region {
  margin-right: 20px;
  margin-top: 5px;
}
.filter-block .select-content .travel-area .a-area:last-child {
  margin-bottom: 0;
}
.filter-block .select-content .cowell-search .c-txt__input {
  width: 350px;
}
.filter-block .select-content .cowell-search .c-txt__input .c-txt__input--media__figure {
  color: var(--dark-sky-blue);
  cursor: pointer;
}
.filter-block .select-content .cowell-search .search-result {
  margin-top: 10px;
  height: 20px;
}
.filter-block .select-content .line-friends p {
  font-size: 14px;
  color: var(--dark-grey);
  margin-bottom: 5px;
}
.filter-block .select-content .line-friends .send-list,
.filter-block .select-content .line-friends .search-friends {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 15px;
}
.filter-block .select-content .line-friends .send-list .a-friend,
.filter-block .select-content .line-friends .search-friends .a-friend {
  margin: 5px 5px 0 0;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ffffff;
  border-radius: 3px;
}
.filter-block .select-content .line-friends .send-list .a-friend figure,
.filter-block .select-content .line-friends .search-friends .a-friend figure {
  width: 25px;
  height: 25px;
}
.filter-block .select-content .line-friends .send-list .a-friend figure img,
.filter-block .select-content .line-friends .search-friends .a-friend figure img {
  border-radius: 50%;
}
.filter-block .select-content .line-friends .send-list .a-friend .real-name,
.filter-block .select-content .line-friends .search-friends .a-friend .real-name {
  margin-left: 10px;
  color: var(--dark-grey);
}
.filter-block .select-content .line-friends .send-list .a-friend .nick-name,
.filter-block .select-content .line-friends .search-friends .a-friend .nick-name {
  color: var(--dark-grey);
}
.filter-block .select-content .line-friends .send-list .a-friend .control,
.filter-block .select-content .line-friends .search-friends .a-friend .control {
  margin-left: 5px;
}
.filter-block .select-content .line-friends .c-txt__input {
  width: 100%;
  margin-bottom: 5px;
}
.filter-block .select-content .line-friends .c-txt__input .c-txt__input--media__figure {
  color: var(--dark-sky-blue);
  cursor: pointer;
}
.filter-block .select-content .line-friends .search-result {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 20px;
}
