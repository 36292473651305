.login-page {
  background-color: #fafafb;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}
.login-page .login-block {
  width: 300px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 3px;
  margin: 150px 0 auto 0;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
}
.login-page .login-block .logo-part {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.login-page .login-block .logo-part figure {
  width: 250px;
  height: 40px;
}
.login-page .login-block .logo-part figure img {
  object-fit: contain;
}
.login-page .login-block .show-privacy .content-title {
  text-align: center;
  margin-bottom: 20px;
}
.login-page .login-block .show-privacy .privacy-content {
  height: 200px;
  overflow: auto;
  font-size: 11px;
  color: #aeb1b8;
  letter-spacing: 0.6px;
  white-space: pre-line;
}
.login-page .login-block .block-title {
  margin: 15px 0;
}
.login-page .login-block .block-title .orUseGoogle {
  margin-left: 10px;
  font-size: 12px;
  color: #aeb1b8;
  cursor: pointer;
}
.login-page .login-block .block-title .orUseGoogle span {
  color: #359ced;
}
.login-page .login-block .choose-login-type button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
}
.login-page .login-block .choose-login-type button figure {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.login-page .login-block .choose-login-type button figure img {
  border-radius: 50%;
}
.login-page .login-block .cowell-form .c-txt__input--select {
  margin-bottom: 10px;
  max-width: 100%;
  width: inherit;
}
.login-page .login-block .cowell-form button {
  width: 100%;
  margin-bottom: 15px;
}
.login-page .login-block .service-privacy {
  font-size: 10px;
  color: #aeb1b8;
}
.login-page .login-block .service-privacy span {
  color: #359ced;
  cursor: pointer;
}
