.bot-sheet {
  padding: 15px;
}
.bot-sheet .form-text.title {
  font-size: 18px;
  color: var(--dark-grey);
  font-weight: 600;
  margin-bottom: 15px;
}
.bot-sheet .form-text.description {
  font-size: 15px;
  color: var(--dark-grey);
  margin-bottom: 20px;
}
.bot-sheet .form-text.policy {
  font-size: 10px;
  color: var(--dark-grey);
  margin-bottom: 20px;
}
.bot-sheet .form-text.policy a {
  text-decoration: none;
  color: var(--dark-sky-blue);
}
.bot-sheet .form-image {
  width: 100%;
  height: 150px;
  margin-bottom: 15px;
}
.bot-sheet .form-input {
  margin-bottom: 15px;
}
.bot-sheet .form-input .input-title {
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.bot-sheet .form-input .input-des {
  color: var(--dark-grey);
  font-size: 12px;
  margin-bottom: 5px;
}
.bot-sheet .form-input .c-txt__input {
  padding: 5px 10px;
  height: 30px;
  min-height: 30px;
}
.bot-sheet .form-input .checkbox-list {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.bot-sheet .form-input .checkbox-list .input-checkbox-block {
  margin-right: 20px;
  margin-bottom: 5px;
}
.bot-sheet .form-input .checkbox-list .input-checkbox-block label {
  margin-left: 5px;
}
.bot-sheet .sheet-buttons {
  margin-top: 25px;
}
.bot-sheet .sheet-buttons button {
  margin-bottom: 10px;
  width: 100%;
}
