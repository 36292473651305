.fix-position-tooltip {
  position: relative;
  padding-left: 5px;
}
.fix-position-tooltip .tooltip-icon i {
  line-height: 22px;
  color: var(--cool-grey);
}
.fix-position-tooltip .tooltip-icon:hover + .tooltip-text {
  transition: opacity 0.5s ease-out 0s;
  opacity: 1;
}
.fix-position-tooltip .tooltip-text {
  z-index: 1;
  position: absolute;
  left: 22px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px var(--white-three);
  background-color: #ffffff;
  font-size: 12px;
  color: var(--gunmetal);
  opacity: 0;
  transition: opacity 1s ease-in 1.5s;
}
