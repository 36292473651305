.plus-button {
  width: 25px;
  height: 25px;
  background-color: var(--ice-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
}
.plus-button:focus {
  outline: 0;
}
