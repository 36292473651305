.chat-preview {
  width: 100%;
  height: 640px;
  background: url('https://lineline.s3.amazonaws.com/line-tutorial-images/iphone.png') no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
}
.chat-preview .chat-history {
  margin-top: 46px;
  width: 250px;
  height: 510px;
  overflow: hidden;
  padding-bottom: 0;
}
.chat-preview .chat-history .histories {
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
}
.chat-preview .chat-history .histories .chat-dialogue {
  margin: 20px 10px 0 10px;
}
.chat-preview .chat-history .histories .chat-dialogue .user-avatar {
  width: 48px;
  flex: 0;
}
.chat-preview .chat-history .histories .chat-dialogue .user-avatar figure {
  width: 34px;
  height: 34px;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content {
  width: calc(100% - 68px);
  margin-left: 20px;
  flex: 0;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .name-n-time {
  align-items: flex-start;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .name-n-time .speaker {
  font-size: 12px;
  margin-right: 0;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .name-n-time .message-time {
  font-size: 12px;
  transform: scale(0.6);
  width: 150px;
  white-space: nowrap;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .multi-files {
  overflow-x: auto;
  width: 180px;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .multi-files .a-file {
  padding: 5px;
  margin-right: 10px;
  min-width: 140px;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .file-thumbnail {
  width: 180px;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .file-thumbnail a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .file-thumbnail .preview-block {
  margin-top: 8px;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .file-thumbnail .preview-block .file-info {
  padding-left: 5px;
  margin-right: 2px;
  width: calc(100% - 47px);
  font-size: 12px;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .file-thumbnail .preview-block .file-info .file-name {
  font-size: 12px;
}
.chat-preview .chat-history .histories .chat-dialogue .dialogue-content .file-thumbnail .preview-block figure {
  width: 45px;
  height: 45px;
}
.chat-preview .chat-history .histories .chat-dialogue.image .dialogue-content a {
  width: 180px;
  height: 150px;
}
.chat-preview .chat-history .histories .chat-dialogue.rich-image .dialogue-content figure {
  width: 180px;
  height: 150px;
}
.chat-preview .chat-history .histories .chat-dialogue.hide-avatar .dialogue-content {
  flex: 0 0 100%;
}
.chat-preview .chat-history .histories .chat-dialogue.activity-card .dialogue-content .content-section {
  width: 160px;
}
.chat-preview .bot-sheet {
  margin-top: 46px;
  width: 250px;
  height: 510px;
  overflow-y: scroll;
}
