.channel-broadcast {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 20px 70px 20px;
  position: relative;
}
.channel-broadcast .broadcast-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.channel-broadcast .broadcast-list .a-broadcast {
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
}
.channel-broadcast .broadcast-list .a-broadcast:first-child {
  padding-top: 0;
}
.channel-broadcast .broadcast-list .a-broadcast .sending-status {
  flex: 0 0 60px;
}
.channel-broadcast .broadcast-list .a-broadcast .sending-status .msg-unsend,
.channel-broadcast .broadcast-list .a-broadcast .sending-status .msg-sent {
  width: 60px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.channel-broadcast .broadcast-list .a-broadcast .sending-status .msg-unsend {
  background-color: #e6f4ff;
  color: #141516;
}
.channel-broadcast .broadcast-list .a-broadcast .sending-status .msg-sent {
  color: #ffffff;
  background-color: #359ced;
}
.channel-broadcast .broadcast-list .a-broadcast .msg-title-n-time {
  margin-left: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.channel-broadcast .broadcast-list .a-broadcast .msg-title-n-time .msg-title {
  color: #141516;
  font-size: 18px;
  text-decoration: unset;
  margin-bottom: 10px;
  min-height: 25px;
}
.channel-broadcast .broadcast-list .a-broadcast .msg-title-n-time .op-name {
  font-size: 12px;
  margin-bottom: 23px;
}
.channel-broadcast .broadcast-list .a-broadcast .msg-title-n-time .scheduled-sending-time {
  color: #aeb1b8;
  font-size: 12px;
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics {
  flex: 0 0 280px;
  display: flex;
  flex-direction: column;
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group .far {
  color: var(--cool-grey);
  font-size: 12px;
  margin-left: 15px;
  cursor: pointer;
  line-height: 25px;
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group .fa-trash:hover {
  color: var(--watermelon);
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group .fa-trash:active {
  color: var(--faded-red);
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group .fa-copy:hover,
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group .fa-pen:hover {
  color: var(--dark-sky-blue);
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group .fa-copy:active,
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group .fa-pen:active {
  color: var(--bluish);
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .button-group .far.disabled {
  color: var(--white-two);
  cursor: initial;
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .msg-statics {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .msg-statics .sent-people,
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .msg-statics .deliveray-rate {
  width: 120px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .msg-statics .sent-people p,
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .msg-statics .deliveray-rate p {
  margin: 0;
}
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .msg-statics .sent-people .big-number,
.channel-broadcast .broadcast-list .a-broadcast .buttons-n-statics .msg-statics .deliveray-rate .big-number {
  font-size: 18px;
  color: #359ced;
  margin-bottom: 10px;
}
.broadcast-detail {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 20px 70px 20px;
}
.broadcast-detail .sub-title {
  margin-bottom: 15px;
}
.broadcast-detail input.group-msg-name {
  margin-bottom: 20px;
}
.broadcast-detail input[type=number]::-webkit-inner-spin-button {
  content: ' ';
  opacity: 1;
  display: block;
  border: 0;
}
.broadcast-detail .msg-recipient-block {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.broadcast-detail .msg-recipient-block .choose-recipient .select-title {
  color: #141516;
  margin-bottom: 15px;
  font-size: 18px;
}
.broadcast-detail .msg-recipient-block .recipient-result {
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.broadcast-detail .msg-recipient-block .recipient-result .result-title {
  color: #141516;
  margin-bottom: 15px;
  font-size: 18px;
}
.broadcast-detail .msg-recipient-block .recipient-result .result-percent {
  color: #359ced;
  font-size: 20px;
  margin-bottom: 10px;
}
.broadcast-detail .msg-recipient-block .recipient-result .result-numbers {
  font-size: 13px;
}
.broadcast-detail .add-filter-block {
  width: 185px;
  margin-top: 10px;
}
.broadcast-detail .sending-time-block {
  margin: 25px 0;
}
.broadcast-detail .sending-time-block .setting {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.broadcast-detail .sending-time-block .setting .sending-time-type-select {
  height: 33px;
  line-height: 35px;
  padding: 0 10px;
  width: 170px;
}
.broadcast-detail .sending-time-block .setting .sending-time-type-select::before {
  top: 0;
}
.broadcast-detail .sending-time-block .setting .sending-time {
  margin-left: 20px;
}
.broadcast-detail .sending-time-block .setting .date-input {
  margin: 0 20px;
  height: 35px;
  width: 190px;
}
.broadcast-detail .sending-time-block .setting .seperater {
  margin: 0 10px;
}
.broadcast-detail .preview-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}
.broadcast-detail .preview-block .sub-title {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
}
.broadcast-detail .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.broadcast-detail .button-group button {
  margin: 0 10px;
}
.broadcast-detail .chiffon {
  height: 100%;
  width: 100%;
  background-color: rgba(174, 177, 184, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
