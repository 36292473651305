.landing-page {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.landing-page .sub-title {
  font-size: 20px;
  color: #141516;
}
.landing-page header {
  height: 750px;
  padding: 20px 30px 0px 30px;
  background: no-repeat url('https://lineline.s3.amazonaws.com/icons/banner1bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
}
.landing-page header img {
  position: absolute;
  top: 21%;
  right: 15%;
}
.landing-page header .top-section {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  text-align: right;
  height: 40px;
  line-height: 40px;
}
.landing-page header .top-section a {
  text-decoration: unset;
  color: #ffffff;
  padding: 10px 35px;
  margin-left: 15px;
  font-size: 17px;
  font-weight: 300;
  white-space: nowrap;
}
.landing-page header .top-section a.with-border {
  border: 2px solid #ffffff;
  border-radius: 3px;
}
.landing-page header .main-des {
  margin: 240px auto auto 22%;
  max-width: 450px;
}
.landing-page header .main-des h1 {
  font-size: 30px;
  color: #141516;
  margin-bottom: 30px;
}
.landing-page header .main-des p {
  margin-bottom: 30px;
}
.landing-page .main-des-center {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 20px 20px 20px;
}
.landing-page .main-des-center .main-des {
  flex-direction: column;
}
.landing-page .main-des-center .main-des h1 {
  font-size: 30px;
  color: #141516;
  margin-bottom: 30px;
}
.landing-page .main-des-center .main-des a {
  max-width: 160px;
}
.landing-page .main-des-center .main-des p {
  margin-top: 0px;
  margin-bottom: 30px;
}
.landing-page .potential-customer {
  max-width: 100%;
  background: no-repeat url('https://lineline.s3.amazonaws.com/icons/banner2.png');
  background-size: 100% 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
  padding: 0 20px;
}
.landing-page .potential-customer .sub-title {
  margin-bottom: 25px;
}
.landing-page .potential-customer .sub-des {
  width: 525px;
  text-align: center;
}
.landing-page .features-section {
  max-width: 100%;
  padding: 55px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.landing-page .features-section .a-feature {
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.landing-page .features-section .a-feature .feature-info {
  max-width: 600px;
}
.landing-page .features-section .a-feature .feature-info .platform-feature {
  font-size: 14px;
  color: #aeb1b8;
  margin-bottom: 10px;
}
.landing-page .features-section .a-feature .feature-info .sub-title {
  margin-bottom: 15px;
}
.landing-page .features-section .a-feature img {
  object-fit: cover;
}
.landing-page .features-section .a-feature.reverse {
  flex-direction: row-reverse;
}
.landing-page .user-recommend {
  max-width: 100%;
  background: no-repeat url('https://lineline.s3.amazonaws.com/icons/banner3.png');
  background-size: 100% 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 300px;
  padding: 45px 20px;
}
.landing-page .user-recommend .a-recommend {
  width: 370px;
  height: 100%;
}
.landing-page .user-recommend .a-recommend .recommend-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #141516;
}
.landing-page .user-recommend .a-recommend .recommend-title .fa-quote-left {
  margin-right: 50px;
}
.landing-page .user-recommend .a-recommend .recommend-title .fa-quote-right {
  margin-left: 50px;
}
.landing-page .user-recommend .a-recommend .recommend-des {
  font-size: 15px;
  text-align: center;
}
.landing-page .user-recommend .a-recommend hr {
  border-color: #707070;
  margin: 25px auto;
  width: 200px;
}
.landing-page .user-recommend .a-recommend .recommender {
  text-align: center;
  font-size: 14px;
}
.landing-page .plan-block {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 565px;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}
.landing-page .plan-block .plan-info {
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-right: 65px;
}
.landing-page .plan-block .plan-info .sub-title {
  margin-bottom: 20px;
}
.landing-page .plan-block .a-plan {
  width: 355px;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  margin: 0 15px;
}
.landing-page .plan-block .a-plan .top {
  height: 60px;
  padding: 20px;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
  background-color: #fafafb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.landing-page .plan-block .a-plan .top .plan-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  color: #141516;
  height: 100%;
}
.landing-page .plan-block .a-plan .top .plan-name span {
  color: #aeb1b8;
  font-size: 14px;
}
.landing-page .plan-block .a-plan .top .plan-fee {
  color: #359ced;
  font-size: 22px;
}
.landing-page .plan-block .a-plan .plan-brief {
  padding: 30px 0;
  text-align: center;
}
.landing-page .plan-block .a-plan .plan-brief p {
  font-size: 16px;
}
.landing-page .plan-block .a-plan .plan-brief p:nth-child(2n) {
  color: #aeb1b8;
}
.landing-page .plan-block .a-plan.disabled .top .plan-fee {
  color: #606972;
}
.landing-page .plan-block .a-plan.disabled .plan-brief a {
  color: white;
}
.landing-page footer {
  height: 320px;
  padding: 40px 0;
  background-color: #1d2124;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #ffffff;
  font-size: 16px;
}
.landing-page footer .menus {
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.landing-page footer .menus span {
  margin: 0 25px;
}
.landing-page footer .social-media {
  margin-bottom: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.landing-page footer .social-media .a-platform {
  width: 30px;
  height: 30px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  cursor: pointer;
}
.landing-page footer .copyright {
  font-size: 12px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .landing-page header {
    background-position: top 10%, center;
  }
  .landing-page header .main-des {
    margin-left: 10%;
  }
}
@media only screen and (max-width: 1400px) {
  .landing-page header .main-des {
    display: none;
  }
  .landing-page .main-des-center {
    display: flex;
  }
}
@media only screen and (max-width: 1000px) {
  .landing-page header {
    height: auto;
  }
  .landing-page header img {
    object-fit: contain;
    position: initial;
    margin: 20px auto 0 auto;
    width: 100%;
  }
  .landing-page .features-section .a-feature {
    flex-direction: column;
  }
  .landing-page .features-section .a-feature .feature-info {
    margin-bottom: 40px;
    max-width: 500px;
  }
  .landing-page .features-section .a-feature .feature-info .sub-title {
    font-size: 20px;
  }
  .landing-page .features-section .a-feature img {
    object-fit: cover;
    width: 100%;
    max-width: 500px;
  }
  .landing-page .features-section .a-feature.reverse {
    flex-direction: column;
  }
  .landing-page .plan-block {
    flex-direction: column;
    height: auto;
    margin: 50px 20px 90px 20px;
  }
  .landing-page .plan-block .plan-info {
    margin: 0 0 30px 0;
  }
  .landing-page .plan-block .plan-info .sub-title {
    margin-bottom: 15px;
    font-size: 20px;
  }
  .landing-page .plan-block .a-plan {
    margin-bottom: 10px;
  }
}
