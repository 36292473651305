.validation-page {
  background-color: #fafafb;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}
.validation-page .result-block {
  width: 300px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 3px;
  margin: 150px 0 auto 0;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
}
.validation-page .block-title {
  font-size: 16px;
  margin-bottom: 15px;
}
.validation-page .block-title span {
  color: var(--watermelon);
  font-size: 12px;
  margin-left: 10px;
}
.validation-page .c-btn {
  width: 100%;
  margin-bottom: 15px;
}
.validation-page .contact-us {
  font-size: 0.625em;
  color: var(--cool-grey);
}
.validation-page .contact-us a {
  color: var(--dark-sky-blue);
  text-decoration: unset;
}
