.date-input {
  position: relative;
  cursor: pointer;
}
.date-input .c-txt__input--media {
  width: 100%;
  height: 100%;
  min-height: 100%;
  cursor: pointer;
  align-items: center;
}
.date-input .c-txt__input--media input {
  font-size: 12px;
  height: 100%;
  cursor: pointer;
}
.date-input .c-txt__input--media .c-txt__input--media__figure {
  cursor: pointer;
  margin: 0 5px;
}
.date-input .c-txt__input--media .c-txt__input--media__figure .fa-calendar-alt:hover {
  color: #359ced;
}
.date-input .c-txt__input--media input[type=date]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}
.date-input .calendar-modal {
  background-color: transparent;
  width: 100%;
  min-width: 190px;
}
.date-input .calendar-modal .calendar {
  margin-top: 5px;
}
