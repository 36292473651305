.step-block {
  display: flex;
  flex-direction: row;
  min-height: 180px;
  margin-bottom: 10px;
}
.step-block .seq-icon {
  padding-left: 2%;
  flex-basis: 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.step-block .seq-icon .icon-title {
  font-size: 12px;
  text-align: center;
  width: 24px;
  color: #359ced;
  margin-bottom: 3px;
}
.step-block .seq-icon .icon-seq {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  background-color: #359ced;
}
.step-block .seq-icon .icon-decor-line {
  position: relative;
  flex: 1;
}
.step-block .seq-icon .icon-decor-line:after {
  height: 100%;
  content: "";
  border: 1.5px solid #359ced;
  position: absolute;
  left: 50%;
  margin-left: -1.5px;
  top: 0;
}
.step-block .description {
  flex: 1;
  margin-left: 20px;
}
.step-block .description .des-title {
  font-weight: 600;
  padding: 10px 0px;
  font-size: 16px;
  margin: 10px 0;
}
.step-block .description input {
  font-size: 13px;
  color: #aeb1b8;
}
.step-block .description .input-radio-block {
  display: inline-block;
}
.step-block .description .c-txt__input--media .c-txt__input--media__figure {
  cursor: pointer;
}
.step-block .description .c-txt__input--media .c-txt__input--media__figure .fa-check {
  color: #359ced;
}
.step-block .description .des-paragraph {
  font-size: 13px;
  line-height: 1.6;
  font-weight: 300;
  margin-bottom: 10px;
}
.step-block .description .des-paragraph a {
  color: #3dcca2;
  margin: 0 5px;
}
.step-block .description .des-paragraph b {
  margin: 0 5px;
}
.step-block .description .des-paragraph span {
  margin-right: 10px;
}
.step-block .description .des-paragraph img {
  margin: 0 5px;
  height: 18px;
  vertical-align: middle;
}
.step-block .description .des-paragraph .fal {
  margin: 0 5px;
}
.step-block .demo-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin-left: 30px;
}
.step-block .demo-image img {
  max-height: 100%;
}
.step-block.step-7 .demo-image img {
  width: 175px;
}
