.activity-date {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.activity-date span {
  margin: 0 10px;
}
.activity-date .date-input {
  width: 150px;
  height: 35px;
}
.activity-date .expired-select {
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  margin-right: 15px;
  width: 150px;
}
.activity-date .expired-select:before {
  top: 0;
}
.activity-date .expired-select.disabled {
  color: #c2c8cc;
  cursor: default;
}
