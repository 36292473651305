.input-wrapper {
  position: relative;
  line-height: 100%;
}
.input-wrapper .c-txt__input {
  height: 100%;
  min-height: 100%;
  padding-right: 25px;
}
.input-wrapper::before {
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  vertical-align: center;
  font-size: 16px;
  z-index: 2;
  font-weight: 300;
  right: 10px;
}
.input-wrapper.error .c-txt__input {
  border-color: #f6545f;
}
.input-wrapper.error .c-txt__input:focus:not(.is-disabled):not([disabled]),
.input-wrapper.error .c-txt__input:hover:not(.is-disabled):not([disabled]) {
  border-color: #f6545f;
}
.input-wrapper.error::before {
  content: "\f00d";
  color: #f6545f;
}
.input-wrapper.success .c-txt__input {
  border-color: #3dcca2;
}
.input-wrapper.success .c-txt__input:focus:not(.is-disabled):not([disabled]),
.input-wrapper.success .c-txt__input:hover:not(.is-disabled):not([disabled]) {
  border-color: #3dcca2;
}
.input-wrapper.success::before {
  content: "\f00c";
  color: #3dcca2;
}
