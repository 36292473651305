body {
  background-color: var(--pale-grey);
}
.line-notify-tutorial {
  height: 100vh;
  overflow-y: auto;
}
.line-notify-tutorial .line-notify-tutorial-content {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 20px 40px 20px;
}
.line-notify-tutorial .line-notify-tutorial-content .page-title {
  font-size: 25px;
  padding: 30px 0;
  font-weight: 600;
}
.line-notify-tutorial .line-notify-tutorial-content .sub-title {
  font-size: 18px;
  color: #141516;
}
.line-notify-tutorial .line-notify-tutorial-content .content-description {
  padding: 20px 0;
  font-size: 13px;
}
.line-notify-tutorial .line-notify-tutorial-content a {
  text-decoration: unset;
}
.line-notify-tutorial .line-notify-tutorial-content .step-list .des-paragraph {
  font-size: 14px;
  line-height: 24px;
}
.line-notify-tutorial .line-notify-tutorial-content .step-list .des-paragraph p img {
  height: 20px;
}
.line-notify-tutorial .line-notify-tutorial-content .step-list .des-paragraph-image {
  display: flex;
  justify-content: space-around;
}
.line-notify-tutorial .line-notify-tutorial-content .step-list .des-paragraph-image img.step2-1 {
  width: 300px;
  height: 436px;
}
.line-notify-tutorial .line-notify-tutorial-content .step-list .des-paragraph-image img.step2-2 {
  width: 300px;
  height: 252px;
}
.line-notify-tutorial .line-notify-tutorial-content .step-list .des-paragraph-image img.step3-1 {
  width: 266px;
  height: 100px;
}
.line-notify-tutorial .line-notify-tutorial-content .step-list .des-paragraph-image img.step3-2 {
  width: 390px;
  height: 100px;
}
.line-notify-tutorial .line-notify-tutorial-content .step-list .sub-des-paragraph {
  display: flex;
  justify-content: space-between;
}
