.channel-activity {
  width: 100%;
  max-width: 950px;
  min-width: 700px;
  margin: 0 auto;
  padding: 0 20px 70px 20px;
  position: relative;
}
.channel-activity .page-title-with-button .buttons-section {
  display: flex;
}
.channel-activity .page-title-with-button .buttons-section .input-radio-block {
  margin: 0;
  margin-right: 15px;
}
.channel-activity .activity-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.channel-activity .activity-list .a-activity {
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
}
.channel-activity .activity-list .a-activity:first-child {
  padding-top: 0;
}
.channel-activity .activity-list .a-activity .sending-status {
  flex: 0 0 60px;
  height: 25px;
  font-size: 12px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.channel-activity .activity-list .a-activity .sending-status.unStart {
  color: var(--dark-grey);
  background-color: var(--ice-blue);
}
.channel-activity .activity-list .a-activity .sending-status.active {
  color: #ffffff;
  background-color: var(--dark-sky-blue);
}
.channel-activity .activity-list .a-activity .sending-status.expired {
  color: #ffffff;
  background-color: var(--slate-grey);
}
.channel-activity .activity-list .a-activity .sending-status.deleted {
  color: #ffffff;
  background-color: var(--cool-grey);
}
.channel-activity .activity-list .a-activity .activity-name-n-time {
  margin-left: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.channel-activity .activity-list .a-activity .activity-name-n-time .activity-type {
  font-size: 12px;
  margin-bottom: 5px;
}
.channel-activity .activity-list .a-activity .activity-name-n-time .activity-name {
  color: var(--dark-grey);
  font-size: 18px;
  text-decoration: unset;
  margin-bottom: 10px;
  min-height: 25px;
}
.channel-activity .activity-list .a-activity .activity-name-n-time .op-name {
  font-size: 12px;
  margin-bottom: 5px;
}
.channel-activity .activity-list .a-activity .activity-name-n-time .activity-date {
  color: var(--cool-grey);
  font-size: 12px;
}
.channel-activity .activity-list .a-activity .controls-n-atendees {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .far,
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fal {
  color: var(--cool-grey);
  font-size: 12px;
  margin-left: 15px;
  cursor: pointer;
  line-height: 25px;
}
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fa-trash:hover {
  color: var(--watermelon);
}
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fa-trash:active {
  color: var(--faded-red);
}
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fa-copy:hover,
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fa-pen:hover,
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fa-download:hover {
  color: var(--dark-sky-blue);
}
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fa-copy:active,
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fa-pen:active,
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .fa-download:active {
  color: var(--bluish);
}
.channel-activity .activity-list .a-activity .controls-n-atendees .controls-buttons .far.disabled {
  color: var(--white-two);
  cursor: initial;
}
.channel-activity .activity-list .a-activity .controls-n-atendees .activity-attendees {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.channel-activity .activity-list .a-activity .controls-n-atendees .activity-attendees .big-number {
  font-size: 18px;
  color: var(--dark-sky-blue);
  margin-bottom: 10px;
}
