.friends-list,
.friends-search-result {
  width: 400px;
  border: solid #e9e9e9;
  border-width: 0 1px 0 0;
  overflow-y: auto;
  padding-bottom: 20px;
  height: 100%;
}
.friends-list .loading-section,
.friends-search-result .loading-section {
  padding: 20px;
}
.friends-list .load-more,
.friends-search-result .load-more {
  color: #359ced;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.friends-list .friends-result .result-title,
.friends-search-result .friends-result .result-title,
.friends-list .chat-result .result-title,
.friends-search-result .chat-result .result-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #141516;
  font-size: 15px;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
}
.friends-list .friends-result .result-title .expand-icon,
.friends-search-result .friends-result .result-title .expand-icon,
.friends-list .chat-result .result-title .expand-icon,
.friends-search-result .chat-result .result-title .expand-icon {
  font-size: 15px;
  line-height: 21px;
  padding: 0px 3px;
  cursor: pointer;
}
.friends-list .friends-result .result-title .expand-icon .fa-angle-down.hidden,
.friends-search-result .friends-result .result-title .expand-icon .fa-angle-down.hidden,
.friends-list .chat-result .result-title .expand-icon .fa-angle-down.hidden,
.friends-search-result .chat-result .result-title .expand-icon .fa-angle-down.hidden,
.friends-list .friends-result .result-title .expand-icon fa-angle-right.hidden,
.friends-search-result .friends-result .result-title .expand-icon fa-angle-right.hidden,
.friends-list .chat-result .result-title .expand-icon fa-angle-right.hidden,
.friends-search-result .chat-result .result-title .expand-icon fa-angle-right.hidden {
  display: none;
}
.friends-list .a-friend,
.friends-search-result .a-friend {
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
  cursor: pointer;
}
.friends-list .a-friend.is-current-friend,
.friends-search-result .a-friend.is-current-friend {
  background-color: #f1f1f1;
}
.friends-list .a-friend mark,
.friends-search-result .a-friend mark {
  background-color: transparent;
  color: #359ced;
}
.friends-list .a-friend figure,
.friends-search-result .a-friend figure {
  width: 50px;
  height: 50px;
  position: relative;
}
.friends-list .a-friend figure img,
.friends-search-result .a-friend figure img {
  border-radius: 50%;
}
.friends-list .a-friend figure .noti-dot,
.friends-search-result .a-friend figure .noti-dot {
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: var(--light-mustard);
  color: #ffffff;
  right: -5px;
  top: -5px;
  font-size: 10px;
  align-items: center;
  justify-content: center;
}
.friends-list .a-friend figure.show-noti .noti-dot,
.friends-search-result .a-friend figure.show-noti .noti-dot {
  display: flex;
}
.friends-list .a-friend .friend-info,
.friends-search-result .a-friend .friend-info {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  margin-left: 10px;
  justify-content: space-between;
}
.friends-list .a-friend .friend-info .name-n-info,
.friends-search-result .a-friend .friend-info .name-n-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.friends-list .a-friend .friend-info .name-n-info .friend-name,
.friends-search-result .a-friend .friend-info .name-n-info .friend-name {
  font-size: 15px;
  color: #141516;
  flex: 1;
}
.friends-list .a-friend .friend-info .name-n-info i,
.friends-search-result .a-friend .friend-info .name-n-info i {
  margin-right: 10px;
  font-size: 14px;
}
.friends-list .a-friend .friend-info .name-n-info i.hidden,
.friends-search-result .a-friend .friend-info .name-n-info i.hidden {
  visibility: hidden;
}
.friends-list .a-friend .friend-info .name-n-info .clickable-icon,
.friends-search-result .a-friend .friend-info .name-n-info .clickable-icon {
  color: var(--cool-grey);
}
.friends-list .a-friend .friend-info .name-n-info .clickable-icon:hover,
.friends-search-result .a-friend .friend-info .name-n-info .clickable-icon:hover {
  color: var(--gunmetal);
}
.friends-list .a-friend .friend-info .name-n-info .clickable-icon.active,
.friends-search-result .a-friend .friend-info .name-n-info .clickable-icon.active {
  color: var(--dark-sky-blue);
}
.friends-list .a-friend .friend-info .name-n-info .fa-user-headset,
.friends-search-result .a-friend .friend-info .name-n-info .fa-user-headset {
  color: var(--watermelon);
  margin-left: 5px;
}
.friends-list .a-friend .friend-info .lastest-msg,
.friends-search-result .a-friend .friend-info .lastest-msg {
  font-size: 13px;
  margin-top: 5px;
}
.friends-list .a-friend .friend-info .search-result,
.friends-search-result .a-friend .friend-info .search-result {
  font-size: 13px;
  margin-top: 5px;
}
.friends-list .a-friend .friend-info .search-result .tags-inputer .c-txt__input,
.friends-search-result .a-friend .friend-info .search-result .tags-inputer .c-txt__input {
  display: none;
}
.friends-list .a-friend .friend-info .search-result .tags-section,
.friends-search-result .a-friend .friend-info .search-result .tags-section {
  margin-top: -10px;
}
.friends-list .a-friend .friend-info .time-from-now,
.friends-search-result .a-friend .friend-info .time-from-now {
  font-size: 10px;
  margin-top: 5px;
  color: #aeb1b8;
}
.friends-list .load-more,
.friends-search-result .load-more {
  color: #359ced;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}
@media only screen and (min-width: 769px) and (max-width: 1366px) {
  .friends-list,
  .friends-search-result {
    width: 280px;
  }
}
@media only screen and (max-width: 768px) {
  .friends-list,
  .friends-search-result {
    width: 100%;
  }
}
