.click-to-copy-input .c-txt__input {
  min-height: 35px;
  height: 35px;
}
.click-to-copy-input .c-txt__input input {
  font-size: 14px;
  line-height: 1.36;
  height: 35px;
  color: #aeb1b8;
}
