.message-center-left {
  width: 400px;
  display: flex;
  flex-direction: column;
  height: inherit;
}
@media only screen and (min-width: 769px) and (max-width: 1366px), only screen and (max-width: 768px) {
  .message-center-left {
    width: 280px;
  }
}
@media only screen and (max-width: 768px) {
  .message-center-left {
    display: none;
    width: 100%;
  }
  .message-center-left.mobile-show {
    display: flex;
  }
}
