.line-connect-setting {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 20px 40px 20px;
}
.line-connect-setting .sub-title {
  font-size: 18px;
  color: #141516;
}
.line-connect-setting .step-list {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}
.line-connect-setting .connect-button {
  margin: 20px 0 0 35px;
}
