.bot-page .qabot-list {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 20px 65px 20px;
  padding-bottom: 80px;
}
.bot-page .qabot-list .a-bot {
  padding-bottom: 30px;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}
.bot-page .qabot-list .a-bot figure {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bot-page .qabot-list .a-bot figure img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.bot-page .qabot-list .a-bot .bot-info {
  width: calc(100% - 140px);
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bot-page .qabot-list .a-bot .bot-info .switch-n-pen {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.bot-page .qabot-list .a-bot .bot-info .switch-n-pen .switch-button {
  position: relative;
}
.bot-page .qabot-list .a-bot .bot-info .switch-n-pen .switch-button label {
  font-size: 13px;
}
.bot-page .qabot-list .a-bot .bot-info .switch-n-pen .fa-pen {
  cursor: pointer;
  font-size: 12px;
  color: var(--cool-grey);
}
.bot-page .qabot-list .a-bot .bot-info .bot-title {
  font-size: 18px;
  color: var(--dark-grey);
  margin-bottom: 10px;
}
.bot-page .qabot-list .a-bot .bot-info .bot-des {
  font-size: 14px;
}
.bot-page .qabot-list .a-bot .bot-info a {
  text-decoration: unset;
}
.bot-page .qabot-list .a-bot:last-child {
  border: 0;
}
.bot-page .bot-setting-content {
  display: flex;
  flex-direction: column;
}
.bot-page .bot-setting-content .setting-part {
  width: 100%;
  max-width: 950px;
}
.bot-page .bot-setting-content .setting-part .button-group {
  display: none;
}
.bot-page .bot-setting-content .preview-part {
  width: 100%;
  max-width: 950px;
  margin-top: 25px;
}
.bot-page .sub-title {
  font-size: 18px;
  color: var(--dark-grey);
  margin-bottom: 15px;
}
.bot-page .sub-des {
  font-size: 13px;
  color: var(--slate-grey);
  margin-bottom: 15px;
}
.bot-page .trigger-question {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  padding: 10px;
  min-height: 100px;
  margin-bottom: 20px;
}
.bot-page .trigger-question.error {
  border-color: var(--watermelon);
}
.bot-page .button-group {
  width: 100%;
  max-width: 950px;
  text-align: center;
  display: block;
}
.bot-page .button-group button {
  width: 140px;
  margin: 0 10px;
}
@media only screen and (min-width: 1650px) and (max-width: 1920px) {
  .bot-page .bot-setting-content {
    flex-direction: row;
  }
  .bot-page .bot-setting-content .setting-part .button-group {
    display: block;
  }
  .bot-page .bot-setting-content .preview-part {
    margin-top: 0;
    padding-left: 30px;
    margin-left: 30px;
    width: calc(100% - 950px);
  }
  .bot-page .button-group {
    display: none;
  }
}
