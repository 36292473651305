.a-dir-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  max-height: 30px;
  overflow: hidden;
  flex-wrap: wrap;
}
.a-dir-row .title-part {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  white-space: nowrap;
}
.a-dir-row .title-part .indent figure {
  width: 12px;
  height: 16px;
}
.a-dir-row .title-part .indent figure img {
  object-fit: contain;
}
.a-dir-row .title-part .indent .fa-ellipsis-v {
  width: 12px;
  color: var(--cool-grey);
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}
.a-dir-row .title-part .dir-name {
  font-size: 12px;
}
.a-dir-row .a-tag {
  padding: 5px;
  background-color: var(--ice-blue);
  border-radius: 3px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  color: var(--black);
  margin-top: 9px;
  margin-right: 5px;
  white-space: nowrap;
}
.a-dir-row .close-button {
  background-color: var(--ice-blue);
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--black);
  margin-top: 9px;
}
.a-dir-row .button-n-blank {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffffff;
  margin-top: 9px;
}
.a-dir-row .button-n-blank .open-button {
  margin-left: 5px;
  background-color: var(--ice-blue);
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--black);
}
.a-dir-row.is-open {
  max-height: initial;
  flex-wrap: wrap;
}
.a-dir-row.indent-1 {
  padding-left: 10px;
}
.a-dir-row.indent-2 {
  padding-left: 20px;
}
