.form-card {
  padding: 20px;
  border: 1px solid var(--white-two);
  background-color: var(--white-three);
  border-radius: 3px;
  margin-bottom: 10px;
}
.form-card .top-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.form-card .top-section .left-section {
  display: flex;
  align-items: center;
}
.form-card .top-section .left-section span {
  font-size: 16px;
}
.form-card .top-section .left-section .filter-select {
  padding: 0 0 0 10px;
  margin-left: 15px;
  width: 300px;
}
.form-card .top-section .left-section .filter-select::before {
  right: 0;
  top: 0;
}
.form-card .top-section .left-section button {
  margin-left: 15px;
  min-width: 80px;
  max-width: 80px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}
.form-card .top-section .right-section .fal {
  color: var(--cool-grey);
  font-size: 12px;
  margin-left: 15px;
  cursor: pointer;
}
.form-card .top-section .right-section .fa-arrow-up:hover,
.form-card .top-section .right-section .fa-arrow-down:hover {
  color: var(--slate-grey);
}
.form-card .top-section .right-section .fa-arrow-up:active,
.form-card .top-section .right-section .fa-arrow-down:active {
  color: var(--dark-grey);
}
.form-card .top-section .right-section .fa-arrow-up.disabled,
.form-card .top-section .right-section .fa-arrow-down.disabled {
  color: var(--white-two);
  cursor: initial;
}
.form-card .top-section .right-section .fa-trash:hover {
  color: var(--watermelon);
}
.form-card .top-section .right-section .fa-trash:active {
  color: var(--faded-red);
}
.form-card .select-content .text-input {
  height: 35px;
  padding: 10px 8px;
  font-size: 14px;
  color: var(--dark-grey);
  margin-top: 20px;
}
.form-card .select-content .uploaded-file {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-card .select-content .uploaded-file figure {
  width: 120px;
  height: 120px;
}
.form-card .select-content .uploaded-file figure img {
  border-radius: 3px;
}
.form-card .select-content .uploaded-file .media-icon {
  width: 120px;
  height: 120px;
  border: 1px solid #aeb1b8;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-card .select-content .uploaded-file .media-icon .fal {
  font-size: 50px;
  color: #aeb1b8;
}
.form-card .select-content .uploaded-file .file-info {
  align-self: flex-start;
  margin-left: 15px;
  font-size: 15px;
}
.form-card .select-content .sheet-input {
  margin-top: 20px;
}
.form-card .select-content .sheet-input .field-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.form-card .select-content .sheet-input .field-row .field-label {
  font-size: 16px;
  white-space: nowrap;
  line-height: 40px;
}
.form-card .select-content .sheet-input .field-row input {
  margin-left: 15px;
  padding: 10px 8px;
  font-size: 14px;
  color: var(--dark-grey);
}
.form-card .select-content .sheet-input .field-row:last-child {
  margin-bottom: 0;
}
.form-card .select-content .sheet-input .tags-inputer {
  margin-left: 15px;
}
.form-card .select-content .sheet-input .tags-inputer input {
  margin-left: 0;
}
.form-card .select-content .sheet-input .tags-inputer .tags-section .a-tag span {
  font-size: 13px;
}
