.activity-statistics .qrcode-download {
  display: flex;
  flex-direction: row;
}
.activity-statistics .qrcode-download figure {
  width: 200px;
  height: 200px;
}
.activity-statistics .qrcode-download .qrcode-info-n-button {
  margin-left: 15px;
  width: calc(100% - 215px);
}
.activity-statistics .qrcode-download .qrcode-info-n-button .qrcode-info-url {
  margin-bottom: 20px;
}
.activity-statistics .qrcode-download .qrcode-info-n-button .qrcode-info-url .qrcode-info-url-input-title {
  font-size: 16px;
  margin-bottom: 10px;
}
.activity-statistics .qrcode-download .qrcode-info-n-button .qrcode-info {
  font-size: 13px;
  color: var(--slate-grey);
  white-space: pre-line;
  line-height: 1.54;
}
.activity-statistics .qrcode-download .qrcode-info-n-button button {
  margin-top: 15px;
}
.activity-statistics .title-n-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.activity-statistics .title-n-date .select-statistic-date {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.activity-statistics .title-n-date .select-statistic-date span {
  margin: 0 10px;
  font-size: 14px;
}
.activity-statistics .title-n-date .select-statistic-date .date-input {
  width: 150px;
  height: 30px;
}
.activity-statistics .statistics-part .a-overview-block {
  border: 1px solid var(--white-two);
  background-color: var(--white-three);
  border-radius: 3px;
  margin-bottom: 10px;
}
.activity-statistics .statistics-part .a-overview-block .block-head {
  color: var(--dark-grey);
  font-size: 18px;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
  padding: 15px 20px;
}
.activity-statistics .statistics-part .a-overview-block .block-head span {
  font-size: 13px;
}
.activity-statistics .statistics-part .a-overview-block .block-head .download {
  color: var(--black);
  font-size: 13px;
  cursor: pointer;
}
.activity-statistics .statistics-part .a-overview-block .block-head .download .fal {
  margin-right: 5px;
}
.activity-statistics .statistics-part .a-overview-block .block-head .download .fa-file-download {
  color: var(--cool-grey);
}
.activity-statistics .statistics-part .a-overview-block .block-head.hide-border {
  border: 0;
}
.activity-statistics .statistics-part .a-overview-block .block-head.with-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.activity-statistics .statistics-part .a-overview-block .block-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.activity-statistics .statistics-part .a-overview-block .block-content .a-section {
  width: 33%;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
}
.activity-statistics .statistics-part .a-overview-block .block-content .a-section .section-title {
  color: var(--slate-grey);
  font-size: 16px;
  margin-bottom: 20px;
}
.activity-statistics .statistics-part .a-overview-block .block-content .a-section .section-content {
  display: flex;
  flex-direction: column;
}
.activity-statistics .statistics-part .a-overview-block .block-content .a-section .section-content .data-number-unit {
  color: var(--slate-grey);
  font-size: 16px;
  align-self: flex-end;
}
.activity-statistics .statistics-part .a-overview-block .block-content .a-section .section-content .data-number-unit span {
  color: var(--dark-grey);
  font-size: 20px;
  font-weight: 600;
}
.activity-statistics .statistics-part .a-overview-block .block-content table {
  width: 100%;
  border-collapse: collapse;
}
.activity-statistics .statistics-part .a-overview-block .block-content table tr {
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
}
.activity-statistics .statistics-part .a-overview-block .block-content table tbody tr:last-child {
  border: 0;
}
.activity-statistics .statistics-part .a-overview-block .block-content table th,
.activity-statistics .statistics-part .a-overview-block .block-content table td {
  padding: 10px 20px;
  border: solid var(--white-two);
  border-width: 0 1px 0 0;
}
.activity-statistics .statistics-part .a-overview-block .block-content table th {
  text-align: left;
}
.activity-statistics .statistics-part .a-overview-block .block-content table td {
  font-size: 13px;
  color: var(--dark-grey);
}
.activity-statistics .statistics-part .a-overview-block .block-content table .order {
  padding: 10px 10px;
}
.activity-statistics .statistics-part .a-overview-block .block-content table .order,
.activity-statistics .statistics-part .a-overview-block .block-content table .participate-counts {
  text-align: center;
}
.activity-statistics .statistics-part .a-overview-block .block-content table .participate-counts {
  width: 110px;
}
.activity-statistics .statistics-part .a-overview-block .block-content table .avatar-name {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.activity-statistics .statistics-part .a-overview-block .block-content table .avatar-name figure {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}
.activity-statistics .statistics-part .a-overview-block .block-content table .avatar-name figure img {
  border-radius: 50%;
}
.activity-statistics .statistics-part .a-overview-block .block-content table .not-join {
  color: #f6545f;
}
.activity-statistics .statistics-part .a-overview-block .block-content table .participate-ways {
  white-space: pre-line;
}
.activity-statistics .statistics-part .a-overview-block .block-content table .conversation .fa-comments {
  cursor: pointer;
  color: var(--cool-grey);
}
.activity-statistics .statistics-part .a-overview-block .block-content table .conversation .fa-comments:hover {
  color: var(--dark-sky-blue);
}
.activity-statistics .statistics-part .a-overview-block .block-content table .conversation .fa-comments:active {
  color: var(--bluish);
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .axis-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .axis-title span {
  color: var(--dark-grey);
  font-size: 12px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper {
  margin-bottom: 30px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper .recharts-surface .recharts-cartesian-grid-horizontal line {
  stroke: #e9e9e9;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper .recharts-surface .recharts-cartesian-axis-line {
  stroke: #aeb1b8;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper .recharts-surface .recharts-xAxis .recharts-cartesian-axis-ticks {
  transform: translateY(10px);
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper .recharts-surface .recharts-text {
  font-size: 13px;
  fill: var(--slate-grey);
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper .custom-tooltip {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--white-two);
  font-size: 14px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper .custom-tooltip .tooltip-title {
  margin-bottom: 5px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper .custom-tooltip .a-row {
  margin: 5px 0;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .recharts-wrapper .custom-tooltip .a-row span {
  margin-left: 5px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .axis-illustration {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .axis-illustration .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .axis-illustration .attendees {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .axis-illustration .attendees .dot {
  background-color: var(--light-mustard);
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .axis-illustration .friends {
  margin-right: 30px;
}
.activity-statistics .statistics-part .a-overview-block .block-content.record-block .axis-illustration .friends .dot {
  background-color: #359ced;
}
.activity-statistics .statistics-part .a-overview-block.last-child {
  margin-bottom: 20px;
}
