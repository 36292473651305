.file-record {
  padding: 10px 20px;
}
.file-record .files .a-file {
  padding: 10px;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--white-two);
  background-color: #ffffff;
  margin-bottom: 10px;
  text-decoration: unset;
}
.file-record .files .a-file figure {
  margin-right: 10px;
  width: 60px;
  height: 60px;
}
.file-record .files .a-file figure img {
  border-radius: 3px;
}
.file-record .files .a-file figure.preview img {
  border: 1px solid var(--white-two);
}
.file-record .files .a-file .file-info {
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
}
.file-record .files .a-file .file-info .file-name {
  font-size: 15px;
  color: var(--dark-grey);
  font-weight: 600;
  margin-bottom: 5px;
}
.file-record .files .a-file .file-info .sender,
.file-record .files .a-file .file-info .date {
  font-size: 12px;
  color: var(--slate-grey);
}
.file-record .files .a-file:last-child {
  margin-bottom: 0;
}
.file-record .load-more {
  margin-top: 20px;
  text-align: center;
  color: var(--dark-sky-blue);
  font-size: 15px;
  cursor: pointer;
}
