.line-form {
  overflow: auto;
  height: 100vh;
}
.line-form .sheet-buttons {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 0 15px;
}
.line-form .sheet-buttons button {
  margin-bottom: 10px;
  width: 100%;
}
