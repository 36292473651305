.user-profile {
  width: 100%;
  padding: 15px 20px;
}
.user-profile .label-input-inline {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.user-profile .label-input-inline .label {
  min-width: 61px;
  font-size: 15px;
  margin-right: 10px;
}
.user-profile .label-input-inline .inputer {
  min-width: 180px;
}
.user-profile .label-input-inline .inputer input {
  font-size: 13px;
}
.user-profile .label-input-inline .date-input,
.user-profile .label-input-inline .valid-input {
  height: 30px;
  line-height: 30px;
}
.user-profile .remark-section {
  margin-bottom: 10px;
}
.user-profile .remark-section .remark-label {
  font-size: 15px;
}
.user-profile .remark-section textarea {
  height: 100px;
  margin-top: 10px;
  font-size: 13px;
}
.user-profile .tags-section {
  margin-bottom: 10px;
}
.user-profile .tags-section .tags-inputer input.c-txt__input {
  font-size: 13px;
}
.user-profile .tags-section .tags-label {
  font-size: 15px;
  margin-bottom: 10px;
}
.user-profile .tags-section .recent-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  margin: 5px;
}
.user-profile .tags-section .recent-tags .a-tag {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-right: 5px;
}
.user-profile .tags-section .recent-tags .a-tag .tag-value {
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-grey);
  padding: 5px 5px 5px 8px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #f4f4f4;
}
.user-profile .tags-section .recent-tags .a-tag .tag-count {
  background-color: white;
  color: var(--slate-grey);
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  padding: 5px;
  min-width: 18px;
}
.user-profile .tags-section .recent-tags .a-tag .add-tag {
  background-color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 0px;
}
.user-profile .tags-section .recent-tags .a-tag .add-tag .fa-plus {
  font-size: 12px;
  font-weight: 300;
  color: var(--cool-grey);
}
.user-profile .tags-section .recent-tags .a-tag .add-tag .fa-plus:hover {
  color: var(--slate-grey);
  cursor: pointer;
}
.user-profile .tags-section .recent-tags .a-tag .add-tag .fa-check {
  font-size: 12px;
  font-weight: 300;
}
.user-profile .user-actions .action-labels {
  font-size: 15px;
  margin-bottom: 10px;
}
.user-profile .user-actions p {
  font-size: 13px;
}
