.a-active-section {
  padding: 15px 20px;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  width: calc((100% - 20px) / 3);
  height: 100%;
}
.a-active-section .top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}
.a-active-section .today {
  display: flex;
  flex-direction: column;
}
.a-active-section .today .today-content {
  display: flex;
  flex-direction: column;
}
.a-active-section .today .today-content .a-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 40px;
}
.a-active-section .today .today-content .a-row .no-n-others {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
}
.a-active-section .today .today-content .a-row .no-n-others .no {
  font-size: 15px;
  color: var(--dark-grey);
  width: 15px;
  margin-right: 10px;
}
.a-active-section .today .today-content .a-row .no-n-others .img-n-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.a-active-section .today .today-content .a-row .no-n-others .img-n-name figure {
  height: 40px;
  width: 40px;
}
.a-active-section .today .today-content .a-row .no-n-others .img-n-name figure img {
  border-radius: 50%;
}
.a-active-section .today .today-content .a-row .no-n-others .img-n-name .name {
  margin-left: 10px;
  font-size: 16px;
  color: var(--dark-grey);
}
.a-active-section .today .today-content .a-row .no-n-others .question {
  color: var(--dark-grey);
  font-size: 16px;
}
.a-active-section .today .today-content .a-row .count {
  font-size: 18px;
  color: var(--dark-grey);
  font-weight: 600;
}
.a-active-section .today .today-content .a-row:last-child {
  margin-bottom: 0;
}
.a-active-section .a-tag {
  padding: 5px 7px;
  background-color: var(--ice-blue);
  height: 30px;
  min-width: 0;
}
.a-active-section .a-tag .a-tag-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.a-active-section .a-tag:hover {
  padding: 0;
}
.a-active-section .a-tag:hover .a-tag-text {
  overflow: visible;
  white-space: inherit;
  background-color: var(--ice-blue);
  padding: 5px 7px;
}
.a-active-section hr {
  margin: 14px 0;
  border: 0.5px solid var(--white-two);
}
.a-active-section .not-today {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.a-active-section .not-today .date {
  margin-right: 5px;
  font-size: 16px;
  color: var(--slate-grey);
}
.a-active-section .not-today .not-today-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.a-active-section .not-today .not-today-row .active-tag,
.a-active-section .not-today .not-today-row .a-question {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.a-active-section .not-today .a-friend {
  margin-left: 10px;
}
.a-active-section .not-today .a-friend figure {
  width: 30px;
  height: 30px;
}
.a-active-section .not-today .a-friend figure img {
  border-radius: 50%;
}
.a-active-section .not-today.activeTag {
  height: 30px;
  margin-top: 5px;
}
.a-active-section .not-today.activeQuestion {
  height: 30px;
  margin-top: 5px;
}
.a-active-section .not-today.activeFriend {
  height: 30px;
  margin-top: 5px;
}
.a-active-section .not-today:last-child {
  margin-bottom: 0;
}
