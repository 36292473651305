.qabot-page {
  margin: 0 auto;
  min-width: 1280px;
  padding: 0 30px 65px 30px;
}
.qabot-page .page-des {
  margin-bottom: 20px;
}
.qabot-page .search-input {
  width: 350px;
  margin-bottom: 5px;
}
.qabot-page .search-input input {
  font-size: 14px;
}
.qabot-page .search-input .fa-search {
  cursor: pointer;
}
.qabot-page .search-input .fa-search:hover {
  color: var(--dark-sky-blue);
}
.qabot-page .search-input.active .fa-search {
  color: var(--dark-sky-blue);
}
.qabot-page .search-result {
  font-size: 13px;
  color: var(--black);
}
.qabot-page .qna-entries {
  display: flex;
  flex-direction: column;
}
.qabot-page .qna-entries .top-buttons {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin: 0 30px 10px 0;
}
.qabot-page .qna-entries .top-buttons .a-button {
  font-size: 13px;
  color: var(--black);
  margin-left: 15px;
  cursor: pointer;
  text-decoration: unset;
}
.qabot-page .qna-entries .top-buttons .a-button .fal {
  color: var(--brownish-grey-two);
  margin-right: 5px;
}
.qabot-page .qna-entries table {
  border-color: var(--white-two);
  border-spacing: 2px;
  border-collapse: collapse;
  border-radius: 3px;
  table-layout: fixed;
  word-wrap: break-word;
}
.qabot-page .qna-entries table td {
  padding: 10px;
  border: 1px solid var(--white-two);
  background-color: #ffffff;
  vertical-align: text-top;
}
.qabot-page .qna-entries table .a-qa-row .is-loading {
  width: 1100px;
}
.qabot-page .qna-entries table .a-qa-row .no {
  width: 40px;
  max-width: 40px;
  min-width: 40px;
  text-align: center;
}
.qabot-page .qna-entries table .a-qa-row .question {
  width: 500px;
  max-width: 500px;
}
.qabot-page .qna-entries table .a-qa-row .answer {
  width: 560px;
}
.qabot-page .qna-entries table .a-qa-row .delete-icon .fa-trash {
  cursor: pointer;
  display: none;
  color: var(--cool-grey);
  font-size: 12px;
  margin-left: 10px;
}
.qabot-page .qna-entries table .a-qa-row .delete-icon .fa-trash:hover {
  color: var(--watermelon);
}
.qabot-page .qna-entries table .a-qa-row .delete-icon .fa-trash:active {
  color: var(--faded-red);
}
.qabot-page .qna-entries table .a-qa-row .empty,
.qabot-page .qna-entries table .a-qa-row .delete-icon {
  padding: 0;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  background-color: transparent;
  border: 0;
}
.qabot-page .qna-entries table .a-qa-row:hover td {
  background-color: var(--white-three);
}
.qabot-page .qna-entries table .a-qa-row:hover .delete-icon .fa-trash {
  display: block;
}
.qabot-page .qna-entries table.add-new-row,
.qabot-page .qna-entries table.table-header {
  font-size: 16px;
  color: var(--black);
  margin-bottom: 2px;
}
.qabot-page .qna-entries table.table-header td {
  max-height: 40px;
}
.qabot-page .qna-entries table.add-new-row {
  cursor: pointer;
}
.qabot-page .qna-entries table.add-new-row td {
  max-height: 35px;
}
.qabot-page .qna-entries table.add-new-row .a-qa-row:hover .no {
  color: var(--dark-sky-blue);
}
.qabot-page .qna-entries table.add-new-row .a-qa-row:active .no {
  color: var(--bluish);
}
