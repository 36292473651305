.search-bar {
  height: 100px;
  min-height: 100px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e9e9e9;
}
.search-bar .search-bar-dispatch-filter {
  display: flex;
  margin-left: 40px;
  margin-right: 20px;
}
.search-bar .search-bar-dispatch-filter .search-bar-dispatch-icon {
  display: flex;
  cursor: pointer;
}
.search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part {
  display: flex;
}
.search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part .filter-select {
  height: 35px;
  min-height: 35px;
  line-height: 35px;
  width: 150px;
  padding: 0 10px;
  margin-right: 10px;
}
.search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part .filter-select:last-child {
  margin-right: 0;
}
.search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part .filter-select::before {
  top: 0;
  right: 0;
}
.search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part .filter-select.disabled {
  color: #b9b9b0;
  cursor: default;
}
.search-bar .search-bar-input {
  display: flex;
  flex-direction: row;
}
.search-bar .search-bar-input .filter-select {
  height: 33px;
  color: var(--warm-grey);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 14px;
  line-height: 23px;
}
.search-bar .search-bar-input .filter-select:before {
  font-size: 12px;
  top: 1px;
  right: -2px;
}
.search-bar .search-bar-input .fa-search {
  cursor: pointer;
}
.search-bar .search-bar-input .fa-search:hover {
  color: var(--dark-sky-blue);
}
.search-bar .search-bar-input .c-txt__input--media {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
  height: 33px;
  min-height: 33px;
}
.search-bar .search-bar-input .c-txt__input--media .c-txt__input {
  height: 33px;
  font-size: 14px;
}
.search-bar .search-bar-input .c-txt__input--media .c-txt__input::-webkit-input-placeholder {
  font-size: 14px;
}
.search-bar .search-bar-input .c-txt__input--media .c-txt__input::-moz-placeholder {
  font-size: 14px;
}
.search-bar .search-bar-input .c-txt__input--media .c-txt__input:-ms-input-placeholder {
  font-size: 14px;
}
.search-bar .search-bar-input .c-txt__input--media .c-txt__input::placeholder {
  font-size: 14px;
}
.search-bar .search-bar-input .c-txt__input--media:focus,
.search-bar .search-bar-input .c-txt__input--media:hover {
  outline: none;
  border-color: #e9e9e9;
}
.search-bar .search-bar-input .c-txt__input--media__figure {
  cursor: pointer;
}
.search-bar .search-bar-input .c-txt__input--media__figure .fa-search {
  cursor: pointer;
}
.search-bar .search-bar-input .c-txt__input--media__figure .fa-search:hover {
  color: #359ced;
}
.search-bar .search-filter {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
}
.search-bar .search-filter span {
  margin-right: 20px;
  cursor: pointer;
  font-size: 14px;
}
.search-bar .search-filter span.active {
  color: var(--dark-sky-blue);
}
@media only screen and (min-width: 769px) and (max-width: 1366px) {
  .search-bar {
    width: 280px;
    min-height: 90px;
    height: 90px;
  }
  .search-bar .search-bar-dispatch-filter {
    margin-left: 20px;
    margin-right: 20px;
  }
  .search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part .filter-select {
    width: 100px;
    font-size: 13px;
  }
  .search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part .filter-select:before {
    top: 0px;
    right: 0px;
  }
  .search-bar .filter-select {
    width: 91px;
    font-size: 13px;
  }
  .search-bar .search-bar-input .c-txt__input--media {
    width: 130px;
  }
  .search-bar .search-bar-input .c-txt__input--media:focus,
  .search-bar .search-bar-input .c-txt__input--media:hover {
    outline: none;
    border-color: #e9e9e9;
  }
}
@media only screen and (max-width: 768px) {
  .search-bar {
    width: 100%;
    height: 65px;
    min-height: 65px;
    padding: 15px 48px 15px 20px;
  }
  .search-bar .search-bar-dispatch-filter {
    margin: 0;
  }
  .search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part .filter-select {
    width: 100px;
    font-size: 13px;
  }
  .search-bar .search-bar-dispatch-filter .search-bar-dispatch-filter-part .filter-select:before {
    top: 0px;
    right: 0px;
  }
  .search-bar .filter-select {
    width: 91px;
    font-size: 13px;
  }
  .search-bar .search-bar-input .c-txt__input--media {
    width: 120px;
  }
  .search-bar .search-bar-input .c-txt__input--media:focus,
  .search-bar .search-bar-input .c-txt__input--media:hover {
    outline: none;
    border-color: #e9e9e9;
  }
}
