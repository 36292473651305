.activity-detail {
  margin: 0 auto;
  padding: 0 30px 65px 30px;
  max-width: 950px;
}
.activity-detail .tabs {
  width: 100%;
  max-width: 950px;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
  display: flex;
  flex-direction: row;
}
.activity-detail .tabs .a-tab {
  color: var(--cool-grey);
  width: 140px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.activity-detail .tabs .a-tab.active {
  color: var(--dark-sky-blue);
  border: solid var(--dark-sky-blue);
  border-width: 0 0 1px 0;
}
.activity-detail .tabs .a-tab.disabled {
  cursor: not-allowed;
}
.activity-detail .sub-title {
  margin-top: 25px;
  font-size: 18px;
  color: var(--dark-grey);
  margin-bottom: 15px;
}
.activity-detail .sub-des {
  font-size: 13px;
  color: var(--slate-grey);
  margin-bottom: 15px;
}
.activity-detail .inline-sub-title {
  font-size: 16px;
  margin-right: 15px;
}
.activity-detail .radio-group {
  display: flex;
}
.activity-detail .radio-group form {
  display: flex;
}
.activity-detail .radio-group form .input-radio-block {
  margin-right: 15px;
}
.activity-detail .activity-setting-content .setting-part .sub-des {
  white-space: pre-line;
}
.activity-detail .activity-setting-content .setting-part .activity-cover-image .upload-image figure {
  width: 181px;
  height: 120px;
}
.activity-detail .activity-setting-content .setting-part .activity-cover-image .upload-image .default-img {
  width: 181px;
  height: 120px;
}
.activity-detail .activity-setting-content .setting-part .activity-cover-image .upload-image .image-info {
  height: auto;
}
.activity-detail .activity-setting-content .setting-part .activity-cover-image .upload-image .image-info .restriction p {
  margin: 5px;
}
.activity-detail .activity-setting-content .setting-part .activity-keywords {
  min-height: 100px;
  border: 1px solid var(--white-two);
  border-radius: 3px;
  background-color: #ffffff;
  padding: 10px;
}
.activity-detail .activity-setting-content .setting-part .activity-keywords.is-error {
  border-color: var(--watermelon);
}
.activity-detail .activity-setting-content .setting-part .create-new-msg .more-msg-button {
  margin-bottom: 0;
}
.activity-detail .activity-setting-content .setting-part textarea {
  height: 70px;
}
.activity-detail .button-group {
  width: 100%;
  max-width: 950px;
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.activity-detail .button-group button {
  width: 140px;
  margin: 0 10px;
}
