.tag-management {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 20px 70px 20px;
  position: relative;
}
.tag-management .tag-management-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.tag-management .tag-management-list .page-des {
  margin-bottom: 10px;
}
.tag-management .tag-management-list .a-tag-section {
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--white-two);
  background-color: var(--white-three);
}
.tag-management .tag-management-list .a-tag-section .section-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 10px;
  border: solid var(--white-two);
  border-width: 0 0 1px 0;
}
.tag-management .tag-management-list .a-tag-section .section-top .title-part {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  font-size: 18px;
  color: var(--black);
}
.tag-management .tag-management-list .a-tag-section .section-top .title-part .indent figure {
  width: 16px;
  height: 16px;
}
.tag-management .tag-management-list .a-tag-section .section-top .title-part .indent figure img {
  object-fit: contain;
}
.tag-management .tag-management-list .a-tag-section .section-top .title-part .indent .fa-ellipsis-v {
  width: 16px;
  color: var(--cool-grey);
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}
.tag-management .tag-management-list .a-tag-section .section-top .title-part input {
  font-size: 18px;
  border: 0;
  background-color: var(--white-three);
}
.tag-management .tag-management-list .a-tag-section .section-top .title-part input:focus {
  outline: 0;
}
.tag-management .tag-management-list .a-tag-section .section-top .control-buttons {
  display: flex;
  flex-direction: row;
}
.tag-management .tag-management-list .a-tag-section .section-top .control-buttons .add-class-button {
  line-height: 12px;
  position: relative;
}
.tag-management .tag-management-list .a-tag-section .section-top .control-buttons .add-class-button .custom-tooltip {
  position: absolute;
  display: none;
  top: 100%;
  left: 100%;
  width: 50px;
  height: 25px;
  font-size: 12px;
  border: 1px solid var(--white-three);
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  line-height: 25px;
  text-align: center;
}
.tag-management .tag-management-list .a-tag-section .section-top .control-buttons .add-class-button:hover .custom-tooltip {
  display: block;
}
.tag-management .tag-management-list .a-tag-section .section-top .control-buttons .far {
  margin-left: 15px;
  font-size: 12px;
}
.tag-management .tag-management-list .a-tag-section .section-bottom {
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0 10px 25px;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-plus-button {
  margin: 5px 10px 0 0;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 5px 0 0;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .tag-friends-count {
  pointer-events: auto !important;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .tag-friends-count:hover {
  visibility: visible !important;
  opacity: 1 !important;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .tag-friends-count .fa-search {
  color: var(--dark-sky-blue);
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .type-light {
  padding: 5px 10px;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .temp-tag {
  width: 60px;
  height: 27px;
  margin: 0 5px;
  border: 1px dashed var(--cool-grey);
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .a-dnd-tag {
  padding: 0px 10px 0px 13px;
  height: 27px;
  display: flex;
  align-items: center;
  background-color: var(--ice-blue);
  border-radius: 3px;
  margin-right: 5px;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .a-dnd-tag input {
  background-color: var(--ice-blue);
  border: 0;
  font-size: 14px;
  color: var(--dark-grey);
  font-family: "San Francisco Display Medium", "Helvetica", -apple-system, BlinkMacSystemFont, sans-serif, system-ui;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .a-dnd-tag input:focus {
  outline: 0;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .a-dnd-tag .fa-times {
  font-size: 16px;
  margin-left: 8px;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .tag-n-tooltip .a-dnd-tag.is-dragging {
  opacity: 0.5;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .empty-tag-drop-section {
  flex: 1;
  margin-top: 5px;
  height: 27px;
  color: var(--black);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tag-management .tag-management-list .a-tag-section .section-bottom .empty-tag-drop-section .preview-tag {
  height: 27px;
  width: 60px;
  border: 1px dashed var(--cool-grey);
  margin: 0 5px;
}
.tag-management .tag-management-list .a-tag-section.can-drag {
  cursor: grab;
}
.tag-management .tag-management-list .a-tag-section.has-child-dir {
  margin-bottom: 3px;
}
.tag-management .tag-management-list .a-tag-section.indent-1 {
  margin-left: 25px;
  width: calc(100% - 25px);
}
.tag-management .tag-management-list .a-tag-section.indent-2 {
  margin-left: 50px;
  width: calc(100% - 50px);
}
.tag-management .tag-management-list .a-tag-section.is-over {
  background-color: var(--very-light-pink-three);
  border-color: var(--very-light-pink-three);
}
.tag-management .tag-management-list .a-tag-section.is-dragging {
  background-color: var(--white-three);
  border-color: var(--white-two);
  opacity: 0.5;
}
.tag-management .tag-management-list .a-blank-section {
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tag-management .tag-management-list .a-blank-section hr {
  width: 100%;
  margin: 0;
  height: 0;
  border-width: 0.5px 0 0.5px 0;
  border-color: var(--dark-sky-blue);
}
