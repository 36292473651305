.input-radio-block {
  margin-bottom: 15px;
}
.input-radio-block .tooltip {
  margin-left: 5px;
}
.input-radio-block .tooltip .fa-info-circle {
  color: var(--cool-grey);
  font-size: 13px;
}
.input-radio-block.disabled {
  color: #c2c8cc;
}
.input-radio-block span {
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}
