.tag-n-tooltip {
  width: fit-content;
  max-width: 100%;
  height: 24px;
  background-color: var(--ice-blue);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px 5px 0;
  color: var(--black);
  padding: 0 3px 0 8px;
}
.tag-n-tooltip.is-parent {
  background-color: #ffffff;
}
.tag-n-tooltip.is-select {
  border: solid 1px var(--dark-sky-blue);
  background-color: #ffffff;
}
.tag-n-tooltip input {
  border: 0;
  color: var(--black);
  height: 22px;
  padding: 0 2px;
  overflow: auto;
}
.tag-n-tooltip input:focus {
  outline: 0;
}
.tag-n-tooltip span {
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  color: var(--black);
  margin-right: 5px;
  cursor: default;
}
.tag-n-tooltip i {
  width: 14px;
  margin-left: 5px;
  cursor: pointer;
  color: var(--cool-grey);
  line-height: normal;
}
.tag-n-tooltip i.is-disabled {
  color: var(--very-light-pink-three);
}
.tag-n-tooltip i.fa-times {
  font-size: 16px;
}
.tag-n-tooltip i.fa-times:hover {
  color: var(--watermelon);
}
.tag-n-tooltip i.fa-times:active {
  color: var(--faded-red);
}
.tag-n-tooltip i.fa-pen {
  font-size: 12px;
}
.tag-n-tooltip .update-modal section {
  width: 360px;
}
.tag-n-tooltip .update-modal section .modal-title {
  font-size: 16px;
}
.tag-n-tooltip .update-modal section .modal-description {
  color: var(--slate-grey);
  font-size: 13px;
  font-weight: normal;
}
.tag-n-tooltip .delete-modal section {
  width: 360px;
}
.tag-n-tooltip .delete-modal section .modal-title {
  font-size: 16px;
}
.tag-n-tooltip .delete-modal section .action-btn {
  border: solid 1px var(--watermelon);
  background-color: #ffffff;
  color: var(--watermelon);
}
.tag-n-tooltip .delete-modal section .action-btn:hover {
  color: var(--faded-red);
}
