.channel-setting {
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  padding: 0 20px 65px 20px;
}
.channel-setting .sub-title {
  font-size: 18px;
  color: var(--dark-grey);
  margin-bottom: 15px;
}
.channel-setting .sub-des {
  font-size: 13px;
  color: var(--slate-grey);
  margin-top: 15px;
}
.channel-setting .sub-sub-title {
  font-size: 14px;
  color: var(--dark-grey);
}
.channel-setting .sub-sub-des {
  font-size: 13px;
  color: var(--slate-grey);
  margin-top: 5px;
}
.channel-setting .c-btn {
  width: 150px;
}
.channel-setting .platfrom-connect-status {
  padding-bottom: 30px;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
}
.channel-setting .platfrom-connect-status .c-tag {
  width: 60px;
  height: 25px;
  margin-left: 20px;
}
.channel-setting .platfrom-connect-status .connect-to-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.channel-setting .platfrom-connect-status .connect-account {
  margin-top: 20px;
  font-size: 13px;
}
.channel-setting .platfrom-connect-status a {
  margin-top: 20px;
}
.channel-setting .set-name-photo-block {
  padding: 30px 0 25px 0;
  border: solid #e9e9e9;
  border-width: 0 0 1px 0;
}
.channel-setting .tooltip-link {
  width: 115px;
}
.channel-setting .tooltip-link a {
  text-decoration: none;
  color: #3dcca2;
  font-size: 13px;
}
.channel-setting .member-block .member-list-header {
  display: flex;
}
.channel-setting .member-block .member-list-header .header {
  margin-right: 15px;
  color: var(--dark-grey);
  font-size: 16px;
}
.channel-setting .member-block .member-list-header .type {
  width: 210px;
}
.channel-setting .member-block .member-list-header .permission {
  width: 150px;
}
.channel-setting .member-block .member-list-header .token {
  display: flex;
}
.channel-setting .member-block .member-list {
  padding: 20px 0;
}
.channel-setting .member-block .member-list .a-member {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.channel-setting .member-block .member-list .a-member .__react_component_tooltip {
  background-color: var(--ice-blue-two);
  border: 0;
  border-radius: 3px;
  box-shadow: none;
}
.channel-setting .member-block .member-list .a-member figure {
  width: 50px;
  height: 50px;
  background-color: #f7d767;
  border-radius: 50%;
}
.channel-setting .member-block .member-list .a-member figure img {
  border-radius: 50%;
}
.channel-setting .member-block .member-list .a-member .member-name {
  margin-left: 15px;
  width: 150px;
  color: var(--dark-grey);
  font-size: 16px;
}
.channel-setting .member-block .member-list .a-member .member-role {
  margin-left: 15px;
  padding: 0 0 0 10px;
}
.channel-setting .member-block .member-list .a-member .member-role::before {
  top: 0;
}
.channel-setting .member-block .member-list .a-member .fa-trash {
  margin-left: 10px;
  font-size: 12px;
}
.channel-setting .member-block .member-list .a-member .update-msg {
  margin-left: 10px;
}
.channel-setting .member-block .member-list .a-member .name-n-sub-name-section {
  display: flex;
  flex-direction: column;
  width: 150px;
  margin-left: 10px;
}
.channel-setting .member-block .member-list .a-member .name-n-sub-name-section .subName-input-section {
  border: 0;
  border-bottom: 2px dashed var(--cool-grey);
  padding: 0 5px;
  background-color: var(--pale-grey);
}
.channel-setting .member-block .member-list .a-member .name-n-sub-name-section .subName-input-section.is-disabled {
  border-bottom-color: var(--pale-grey);
}
.channel-setting .member-block .member-list .a-member .name-n-sub-name-section .subName-input-section .c-txt__input {
  height: 25px;
  padding-top: 5px;
  font-size: 16px;
  color: var(--dark-grey);
  background-color: var(--pale-grey);
}
.channel-setting .member-block .member-list .a-member .name-n-sub-name-section .subName-input-section i {
  line-height: 25px;
}
.channel-setting .member-block .member-list .a-member .name-n-sub-name-section .name-section {
  height: 20px;
  font-size: 14px;
  color: var(--slate-grey);
  padding: 0 5px;
}
.channel-setting .member-block .member-list .a-member .line-token-input-section {
  width: 380px;
  height: 30px;
  padding: 0 6px;
  margin: 0 15px;
}
.channel-setting .member-block button {
  margin-top: 10px;
}
.channel-setting .default-op .btn-group {
  display: flex;
  flex-direction: row;
}
.channel-setting .default-op .btn-group .update-msg {
  margin-left: 20px;
}
.channel-setting .default-op .op-select {
  margin-top: 25px;
  margin-bottom: 20px;
  height: 40px;
  width: 200px;
}
.channel-setting .new-message-notify .title {
  display: flex;
  align-items: center;
}
.channel-setting .new-message-notify .title .sub-title {
  margin-bottom: 0;
  margin-right: 15px;
}
.channel-setting .new-message-notify .title .switch-button .label {
  font-size: 13px;
  color: var(--slate-grey);
}
.channel-setting .new-message-notify .form-fields {
  margin: 20px 0;
}
.channel-setting .new-message-notify .form-fields .inputer-title-token {
  display: flex;
}
.channel-setting .new-message-notify .form-fields .inputer-token {
  margin-top: 10px;
}
.channel-setting .new-message-notify .form-fields .checkbox-send-to-op {
  margin-top: 10px;
}
.channel-setting .new-message-notify .btn-group {
  display: flex;
  flex-direction: row;
}
.channel-setting .new-message-notify .btn-group button {
  margin-right: 20px;
}
.channel-setting .new-message-notify .btn-group .update-msg {
  margin-left: 20px;
}
.channel-setting .unreply-message-notify .title {
  display: flex;
  align-items: center;
}
.channel-setting .unreply-message-notify .title .sub-title {
  margin-bottom: 0;
  margin-right: 15px;
}
.channel-setting .unreply-message-notify .title .switch-button .label {
  font-size: 13px;
  color: var(--slate-grey);
}
.channel-setting .unreply-message-notify .form-fields {
  margin: 20px 0;
}
.channel-setting .unreply-message-notify .form-fields .inputer-title-token {
  display: flex;
}
.channel-setting .unreply-message-notify .form-fields .inputer-token {
  margin-top: 10px;
}
.channel-setting .unreply-message-notify .form-fields .checkbox-send-to-op {
  margin-top: 10px;
}
.channel-setting .unreply-message-notify .form-fields .active-time {
  margin-top: 20px;
}
.channel-setting .unreply-message-notify .form-fields .active-time .inputer-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.channel-setting .unreply-message-notify .form-fields .active-time .inputer-time .range-text {
  margin: 0 10px;
}
.channel-setting .unreply-message-notify .form-fields .active-time .inputer-time .seperater {
  width: 15px;
  margin: 0 5px;
  text-align: center;
}
.channel-setting .unreply-message-notify .form-fields .active-time .checkbox-weekdays {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.channel-setting .unreply-message-notify .form-fields .active-time .checkbox-weekdays .checkbox {
  margin-right: 15px;
}
.channel-setting .unreply-message-notify .btn-group {
  display: flex;
  flex-direction: row;
}
.channel-setting .unreply-message-notify .btn-group button {
  margin-right: 20px;
}
.channel-setting .unreply-message-notify .btn-group .update-msg {
  margin-left: 20px;
}
.channel-setting .page-tail {
  margin-bottom: 65px;
}
