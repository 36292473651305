.time-chooser {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 35px;
  width: fit-content;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
}
.time-chooser .button-minus,
.time-chooser .button-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 100%;
  cursor: pointer;
}
.time-chooser .button-minus .far,
.time-chooser .button-plus .far {
  font-size: 12px;
}
.time-chooser .button-minus:hover,
.time-chooser .button-plus:hover {
  background-color: #f9f9f9;
}
.time-chooser .button-minus:active,
.time-chooser .button-plus:active {
  background-color: #e9e9e9;
}
.time-chooser .current-number {
  width: 25px;
  text-align: center;
  font-size: 14px;
  -webkit-appearance: none;
  border: none;
}
.time-chooser .current-number:focus {
  box-shadow: none;
  outline: none;
}
.time-chooser input[type=number]::-webkit-inner-spin-button,
.time-chooser input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
