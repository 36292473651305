.input-with-icons {
  display: flex;
  border-radius: 3px;
  border: 1px solid var(--white-two);
  background-color: #fff;
}
.input-with-icons.is-disabled {
  border-color: var(--white-two);
  box-shadow: none;
  background-color: #f8f9f9;
  cursor: default;
  color: #c2c8cc;
}
.input-with-icons input {
  border: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  font-size: 14px;
}
.input-with-icons i {
  line-height: 30px;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600;
  color: var(--cool-grey);
}
.input-with-icons .confirm-icon:hover {
  color: var(--dark-sky-blue);
}
.input-with-icons .display-on-hover {
  visibility: hidden;
}
.input-with-icons:hover .display-on-hover {
  visibility: visible;
}
