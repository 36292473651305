.console-menu {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.16);
  height: calc(100vh - 80px);
  position: relative;
  overflow: scroll;
}
.console-menu .a-section {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #359ced;
}
.console-menu .a-section figure {
  width: 50px;
  height: 50px;
  background-color: var(--light-mustard);
  border-radius: 50%;
  position: relative;
}
.console-menu .a-section figure img {
  border-radius: 50%;
}
.console-menu .a-section .dot {
  display: none;
}
.console-menu .a-section .fal {
  font-size: 24px;
}
.console-menu .a-section.active,
.console-menu .a-section:hover {
  background-color: var(--ice-blue);
}
.console-menu .a-section.yellow-dot figure .dot,
.console-menu .a-section.red-dot figure .dot {
  position: absolute;
  content: " ";
  width: 15px;
  height: 15px;
  border: solid 1px #ffffff;
  border-radius: 50%;
  right: 0;
  top: 0;
  display: block;
}
.console-menu .a-section.yellow-dot figure .dot {
  background-color: var(--light-mustard);
}
.console-menu .a-section.red-dot figure .dot {
  background-color: var(--watermelon);
}
.console-menu a {
  text-decoration: unset;
}
@media only screen and (min-width: 769px) and (max-width: 1366px), only screen and (max-width: 768px) {
  .console-menu {
    height: calc(100vh - 60px);
  }
  .console-menu .a-section {
    height: 60px;
  }
  .console-menu .a-section figure {
    height: 40px;
    width: 40px;
  }
}
