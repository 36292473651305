.console-channel .tab-content .cowell-members {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 0 0 90px 0;
  width: 100%;
  min-width: 964px;
  overflow-y: hidden;
}
.console-channel .tab-content .cowell-members .page-title {
  padding-left: 30px;
}
.console-channel .tab-content .cowell-members .cowell-match-title {
  margin-bottom: 15px;
  font-size: 18px;
  color: var(--dark-grey);
  padding-left: 30px;
}
.console-channel .tab-content .cowell-members .cowell-match-des {
  margin-bottom: 15px;
  font-size: 13px;
  padding-left: 30px;
}
.console-channel .tab-content .cowell-members .search-bar-input {
  display: flex;
  flex-direction: row;
  width: 310px;
  padding-left: 30px;
  margin-bottom: 10px;
}
.console-channel .tab-content .cowell-members .search-bar-input .filter-select {
  height: 33px;
  width: 90px;
  min-width: 90px;
  color: var(--warm-grey);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 14px;
  line-height: 23px;
}
.console-channel .tab-content .cowell-members .search-bar-input .filter-select:before {
  font-size: 12px;
  top: 1px;
  right: -2px;
}
.console-channel .tab-content .cowell-members .search-bar-input .fa-search {
  cursor: pointer;
}
.console-channel .tab-content .cowell-members .search-bar-input .fa-search:hover {
  color: var(--dark-sky-blue);
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
  height: 33px;
  min-height: 33px;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media .c-txt__input {
  height: 33px;
  font-size: 14px;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media .c-txt__input::-webkit-input-placeholder {
  font-size: 14px;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media .c-txt__input::-moz-placeholder {
  font-size: 14px;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media .c-txt__input:-ms-input-placeholder {
  font-size: 14px;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media .c-txt__input::placeholder {
  font-size: 14px;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media:focus,
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media:hover {
  outline: none;
  border-color: #e9e9e9;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media__figure {
  cursor: pointer;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media__figure .fa-search {
  cursor: pointer;
}
.console-channel .tab-content .cowell-members .search-bar-input .c-txt__input--media__figure .fa-search:hover {
  color: #359ced;
}
.console-channel .tab-content .cowell-members .advanced-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  width: 100%;
  margin-bottom: 10px;
}
.console-channel .tab-content .cowell-members .advanced-filter span {
  color: var(--dark-grey);
  font-size: 14px;
  width: 70px;
  margin-right: 5px;
}
.console-channel .tab-content .cowell-members .advanced-filter .include-select {
  width: 100px;
  font-size: 14px;
  padding: 0 0 0 10px;
  margin-right: 10px;
}
.console-channel .tab-content .cowell-members .advanced-filter .include-select:before {
  font-size: 12px;
  top: 0px;
}
.console-channel .tab-content .cowell-members .advanced-filter .tags-inputer {
  width: 25px;
  height: 25px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count {
  padding-left: 30px;
  margin-bottom: 10px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter span {
  color: var(--dark-grey);
  font-size: 14px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter .a-tag {
  padding: 3px 10px;
  color: var(--dark-grey);
  margin-left: 10px;
  background-color: var(--ice-blue);
  border-radius: 3px;
  height: 25px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter .compare-select {
  height: 25px;
  margin-left: 10px;
  padding: 0 0 0 10px;
  line-height: 25px;
  width: 100px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter .compare-select::before {
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter .count-inputer {
  margin-left: 10px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter .count-inputer input {
  min-width: 50px;
  font-size: 14px;
  color: var(--dark-grey);
  min-height: 25px;
  height: 25px;
  text-align: center;
  padding: 0 10px;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter .fa-times {
  color: var(--cool-grey);
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter .fa-times:hover {
  color: var(--watermelon);
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter .fa-times:active {
  color: var(--faded-red);
}
.console-channel .tab-content .cowell-members .filter-tag-n-count .tag-filter:last-child {
  margin-bottom: 0;
}
.console-channel .tab-content .cowell-members .search-result-n-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 30px 0 30px;
}
.console-channel .tab-content .cowell-members .search-result-n-controls .search-result {
  color: var(--brownish-grey-two);
  font-size: 13px;
}
.console-channel .tab-content .cowell-members .search-result-n-controls .controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.console-channel .tab-content .cowell-members .search-result-n-controls .controls .a-control-button {
  color: var(--dark-grey);
  font-size: 13px;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: unset;
}
.console-channel .tab-content .cowell-members .search-result-n-controls .controls .a-control-button .fal {
  color: var(--cool-grey);
  font-size: 13px;
  margin-right: 5px;
}
.console-channel .tab-content .cowell-members .pager .largeScreen,
.console-channel .tab-content .cowell-members .pager .smallScreen {
  justify-content: center;
}
.console-channel .tab-content .cowell-members .match-table {
  border-collapse: collapse;
  margin-bottom: 20px;
}
.console-channel .tab-content .cowell-members .match-table th {
  text-align: left;
  color: #141516;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 0 10px 20px;
  border: 1px solid var(--white-two);
}
.console-channel .tab-content .cowell-members .match-table th.matched-line {
  padding-left: 30px;
}
.console-channel .tab-content .cowell-members .match-table td {
  padding: 10px 10px;
  border: 1px solid var(--white-two);
}
.console-channel .tab-content .cowell-members .match-table td .main-des {
  font-size: 15px;
  color: var(--dark-grey);
  margin: 0 0 5px 0;
}
.console-channel .tab-content .cowell-members .match-table td .sub-des {
  font-size: 13px;
  color: var(--slate-grey);
  margin: 0;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line {
  max-width: 330px;
  width: 330px;
  padding-left: 30px;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .a-friend {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  text-decoration: unset;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .a-friend figure {
  width: 50px;
  height: 50px;
  position: relative;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .a-friend figure img {
  border-radius: 50%;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .a-friend .friend-info {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  margin-left: 10px;
  justify-content: space-around;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .a-friend .friend-info .name-n-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .a-friend .friend-info .name-n-time .friend-name {
  font-size: 15px;
  color: #141516;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .a-friend .friend-info .name-n-time .time-from-now {
  font-size: 10px;
  color: #aeb1b8;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .a-friend .friend-info .lastest-msg {
  font-size: 13px;
  color: #606972;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .not-match {
  display: flex;
  flex-direction: row;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .not-match figure {
  width: 50px;
  height: 50px;
}
.console-channel .tab-content .cowell-members .match-table td.matched-line .not-match .no-record {
  color: var(--cool-grey);
  font-size: 15px;
  margin-left: 15px;
}
.console-channel .tab-content .cowell-members .match-table td.line-data {
  max-width: 150px;
  width: 150px;
}
.console-channel .tab-content .cowell-members .match-table td.line-data .user-data-real-name {
  font-size: 15px;
  color: var(--dark-grey);
  margin-bottom: 5px;
}
.console-channel .tab-content .cowell-members .match-table td.line-data .user-data-phone,
.console-channel .tab-content .cowell-members .match-table td.line-data .user-data-email {
  font-size: 13px;
}
.console-channel .tab-content .cowell-members .match-table td.user-tag {
  width: 420px;
}
.console-channel .tab-content .cowell-members .match-table td.user-tag .tag-list {
  height: 90px;
  max-height: 90px;
  overflow: scroll;
}
.console-channel .tab-content .cowell-members .match-table td.user-tag .tag-list .tags-section {
  margin-top: 0px;
}
.console-channel .tab-content .cowell-members .match-table td.recent-travel {
  max-width: 300px;
  max-height: 90px;
  width: 300px;
  overflow: scroll;
}
.console-channel .tab-content .cowell-members .match-table td.see-detail {
  padding: 0;
  width: 80px;
}
.console-channel .tab-content .cowell-members .match-table td.see-detail .control-buttons {
  display: flex;
  flex-direction: column;
}
.console-channel .tab-content .cowell-members .match-table td.see-detail .control-buttons .a-control-button {
  min-height: 45px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid var(--white-two);
  border-width: 0;
}
.console-channel .tab-content .cowell-members .match-table td.see-detail .control-buttons .a-control-button .fal {
  color: var(--cool-grey);
  font-size: 15px;
  cursor: pointer;
}
.console-channel .tab-content .cowell-members .match-table td.see-detail .control-buttons .a-control-button .fal:hover {
  color: var(--slate-grey);
}
.console-channel .tab-content .cowell-members .match-table td.see-detail .control-buttons .a-control-button .fal:active {
  color: var(--dark-grey);
}
.console-channel .tab-content .cowell-members .match-table td.see-detail .control-buttons .a-control-button .fal.disabled {
  color: var(--white-two);
  cursor: initial;
}
.console-channel .tab-content .cowell-members .match-table td.see-detail .control-buttons .a-control-button:last-child {
  border-width: 1px 0 0 0;
}
.console-channel .tab-content .cowell-members .match-table tbody tr {
  height: 90px;
}
.console-channel .tab-content .cowell-members .match-table tbody tr:hover {
  background-color: var(--white-three);
}
