.pager .largeScreen {
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .pager .largeScreen {
    display: none;
  }
}
@media only screen and (min-width: 801px) {
  .pager .smallScreen {
    display: none;
  }
}
.pagination .pagination-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pagination .pagination-link {
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 18px;
  height: 18px;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  color: #606972;
  margin: 0 5px;
}
.pagination .pagination-link:hover,
.pagination .pagination-link.is-current {
  background-color: #e9e9e9;
  color: #141516;
}
.pagination .pagination-link.is-disabled {
  color: #e9e9e9;
  cursor: initial;
}
.pagination .pagination-link.is-disabled:hover {
  background-color: transparent;
}
.pagination .pageJumper {
  margin-left: 15px;
}
@media only screen and (max-width: 800px) {
  .pagination .pageJumper {
    display: none;
  }
}
.pagination .pageJumperInput {
  max-width: 60px;
  text-align: center;
}
.pagination .pageJumperText {
  line-height: 36px;
}
.left-menu {
  margin-top: 1.5rem;
}
.right-content {
  margin-top: 1.5rem;
  margin-left: 0.75rem;
}
